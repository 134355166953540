@import '~uikit';

#onetrust-pc-sdk .ot-pc-logo {
    display: none!important;
}

.event-tags {
    position: absolute;
    top: 12px;
    left: 12px;
}
.event-tags .event-tag {
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    width: auto;
    margin-bottom: 4px;
    display: inline-block;
    border-radius: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 160px;

}
.event-tags .event-tag i {
    margin-right: 10px;
}
.availability-tag {
    font-size: 14px;
}

.cart-button-continue {
    margin-top: 40px;
}

.product-header {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    line-height: 30px;
    padding: 10px;
}

.product-header, .product-header:hover {
    color: #000000 !important;
    background-color: #ebebeb !important;
}

select{
	outline: 0;
}
.container {
    width: 100%;
    padding: 0 10%;
}
.banner-area .container{
    width: 100%;
    padding:0;
}

.success-message {
    background-color: lightgreen;
    width: 100%;
    z-index: 9999;
    text-align: center;
    color: white;
    padding: 10px;
}

.danger-message {
    background-color: #ff4500;
    width: 100%;
    z-index: 9999;
    text-align: center;
    color: white;
    padding: 10px;
}

.underline {
    text-decoration: underline;
}

.d-none {
    display: none;
}

.bg-red {
    background-color: #dc3545;
}

.white {
    color: white;
}

.product-sidebar-area {
    margin: 0 10px 0 0;
    border-right: 3px solid #ebebeb;
    padding: 0 40px 0 0;
}

.syotimer-cell__unit {
    display: none;
}
.syotimer__head,
.syotimer__body,
.syotimer__footer,
.syotimer-cell {
    display: inline-block;
}
.syotimer-cell:first-child {
    position: relative;
    padding-right: 5px;
    margin-right: 2px;
}
.syotimer-cell:first-child::after {
    content: ":";
    position: absolute;
    right: 0;
    top: 0;
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus {
    border-width: 1px;
}

.mobile {
    display: none;
}

header{
	background: #ffffff;
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
}

.header-style-3 .header-top {
    padding: 20px 10%;
    transition: all 0.3s ease 0s;
}
.header-style-3 .header-top.stick {
    padding: 15px 10%;
}


.header-logo img{
	height: 90px;
	margin-right: 0;
	transition: height 0.5s;
}
.stick .header-logo img{
	height: 70px;
	transition: height 0.5s;
}
.bt-top > a:hover {
    text-decoration: none;
}
a .bt-activites{
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
	border: 3px solid #000000;
	border-radius: 7px;
	padding: 5px;
	max-width: 210px;
	text-align: center;
	/*margin-top: 14px;*/
}
a .bt-activites:hover{
    color: #ffffff;
    background: #000000;
}

a .bt-newsletter{
	background: #f2f3f6;
	margin-top: 15px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
	border: 3px solid #f2f3f6;
	border-radius: 7px;
	padding: 5px;
	max-width: 210px;
	text-align: center;
}
a .bt-newsletter:hover {
    color: #ffffff;
    background: #000000;
    border: 3px solid #000000;
    text-decoration: none;
}
a.lnk-newsletter:hover {
    text-decoration: none;
}

.bt-newsletter i{
	margin-right: 5px;
}


.stick a .bt-activites{
	font-size: 12px;
	padding: 3px;
}
.stick a .bt-newsletter{
    margin-top: 7px;
	font-size: 11px;
	padding: 5px;
}

.header-logo .title span{
	display: block
}

.header-style-3 .header-search-cart{
    padding-top: 30px;
}

.stick .header-search-cart{
    padding-top: 10px;
}

.header-search-cart .activites {
    padding-top: 0px;
    text-align: right
}
.header-search-cart .link {
    margin-top: 15px;
    text-align: right;
}

.menu-area{
	background: #000000;
	padding: 15px 0;
}
.menu-area.transparent-bar.stick {
    background-color: #000;
    border-bottom: 0 solid #4a90e2;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
    position: fixed;
    top: 114px;
    width: 100%;
    padding: 10px 0 7px 0;
}

.common-style > a {
    color: #ffffff;
	font-size: 26px;
	line-height: 1rem;
	margin: 0;
	padding: 0;
}
.common-style:hover > a {
    color: #d6d6d6;
}
.common-style > a i{
	margin-top: 4px;
}
.header-cart a span.shop-count, .breadcrumb-text ul li a:hover {
    color: #ccc;
}


.inserts img{
	display: block !important;
}

.product-sidebar-single form#searchbox input {
    background: #fff none repeat scroll 0 0;
    color: #333;
    display: inline;
    height: 40px;
    padding: 5px 40px 5px 10px;
    position: relative;
    top: 100%;
    width: 100%;
    z-index: 1;
   box-shadow: 0 0px 0px rgba(85, 85, 85, 0.2);
    border: 3px solid #333333;
    border-radius: 5px;
}
.product-sidebar-single form#searchbox input:focus {
    border: 3px solid #000000
}
.product-sidebar-single .button-search {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    font-size: 0;
    padding: 0;
    position: absolute;
    right: 80px;
    top: 7px;
    z-index: 9;
}



.product-sidebar-single  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}
.product-sidebar-single .bootstrap-select>.dropdown-toggle.bs-placeholder, .product-sidebar-single .bootstrap-select>.dropdown-toggle.bs-placeholder:active, .product-sidebar-single .bootstrap-select>.dropdown-toggle.bs-placeholder:focus, .product-sidebar-single .bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
    color: #000000;
}
.product-sidebar-single .bootstrap-select .btn.btn-default{
	background-image:none;
	background: #f8f8fa;
	border: 0px;
	outline: 0;
	border-radius: 5px;

}
.product-sidebar-single .bootstrap-select .dropdown-toggle:focus, .product-sidebar-single .bootstrap-select>select.mobile-device:focus+.dropdown-toggle{
	outline: 0px
}

.btn-primary {
	color: #fff;
    background-color: #000000;
    border-color: #000000;
}

.content {
  padding-top: 206px;
  background: #f2f3f6;
  min-height: calc(100vh - 363px);
}

.bg-white {
    background-color: #fff;
}
.content.stick {
    padding-top: 170px;
}


.search-top {
    float: left;
}

.icon-top {
    float: right;
}
.icon-top .link{
    display: flex;
    align-items: center;
}


.timer-cart {
    background: #f7f6f8;
    color: #7c7c7c;
    font-size: 14px;
    padding: 8px 10px 5px 15px;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 1px;
    height: 36px;
    margin: 0;
    position: relative;
    top: -3px;
    width: 100px;
}
.timer-cart i{
	margin-right: 7px;
	font-size: 18px;
	color: #000000
}



.single-list-dec a:focus, a:hover {
    color: #000000;
    text-decoration: none;
}
.icon-top .single-list-dec a:focus, a:hover {
    text-decoration: none !important;
}

.newsletter-form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding-left: 10px;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
}

.newsletter-form-input {
    width: 85% !important;
}

.newsletter-form-container input {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: none;
    color: #999;
    font-size: 14px;
    height: 40px;
    letter-spacing: 2px;
    width: 100%;
}

.newsletter-form-container input::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.newsletter-form-container input[type="checkbox"] {
    height: 15px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
    top: 1px;
    width: 17px;
}
.newsletter-form-container label {
    color: #777;
    font-size: 15px;
    font-weight: 400;
}

.newsletter-form-container .password-reset-btn {
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    max-width: 45px;
}

.search-top form#searchbox input {
    background: #ffffff none repeat scroll 0 0;
    color: #333;
    display: inline;
    height: 36px;
    padding: 10px 5px 10px 15px;
    z-index: 1;
    border: 3px solid #ffffff;
    border-radius: 5px;
    margin: 0 10px 0 0;
    font-size:14px;
    width: 450px;
}
.search-top form#searchbox input:focus {
    border: 3px solid #f2f3f6;
    background: #ffffff none repeat scroll 0 0;
}
.search-top button {
	display: inline;
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 17px;
    height: 36px;
    padding: 5px 15px;
    border-radius: 5px;
    border: 3px solid #ffffff;
    font-weight: 700
}
.search-top button:hover {
    background: #ffffff none repeat scroll 0 0;
    color: #000000;
    font-size: 14px;
    height: 36px;
    padding: 5px 15px;
    border-radius: 5px;
    border: 3px solid #f2f3f6;
    font-weight: 700
}


.wizard-form-btn {
    background-color: #28BB83;
    color: #ffffff;
    font-weight: 700;
    padding: 10px 30px;
    border: 2px solid #25946a;
    letter-spacing: 0.4px;
}
.wizard-form-btn:hover, .wizard-form-btn.active {
    background-color: #000000;
    color: #ffffff;
    border: 2px solid #000000;
}



.search-area {
	background: #000000;
}
.search-area .container{
	text-align: center;
	max-width: 1350px;
}
.search-area form#searchbox input {
    background: #ffffff none repeat scroll 0 0;
    color: #333;
    display: inline;
    height: 36px;
    padding: 10px 5px 10px 15px;
    z-index: 1;
    border: 3px solid #ffffff;
    border-radius: 5px;
    margin: 0 10px 0 0;
    font-size:14px;
    width: calc(100% - 80px)
}
.search-area form#searchbox input:focus {
    border: 3px solid #f2f3f6;
    background: #ffffff none repeat scroll 0 0;
}
.search-area button {
	display: inline;
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 17px;
    height: 36px;
    padding: 5px 15px;
    border-radius: 5px;
    border: 3px solid #ffffff;
    font-weight: 700
}
.search-area button:hover {
    background: #ffffff none repeat scroll 0 0;
    color: #000000;
    font-size: 14px;
    height: 36px;
    padding: 5px 15px;
    border-radius: 5px;
    border: 3px solid #f2f3f6;
    font-weight: 700
}



.filter-area {
	background: #f2f3f6;
}
.filter-area .container{
	text-align: center;

}
.filter-area .widget {
	display: inline-block;
	margin: -10px 5px 5px 5px;
}

.filter-area .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 170px;
}
.filter-area .spectacle .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 180px;
}
.filter-area .bootstrap-select .dropdown-toggle .filter-option {
    font-size: 12px;
}

.filter-area .bootstrap-select .btn.btn-default {
    background-image: none;
    background: #f8f8fa;
    border: 0px;
    outline: 0;
    border-radius: 5px;
	color: #333333;
}
.filter-area .widget h3{
	display: inline-block;
}

.filter-area #sandbox-container2{
	width: 200px;
	position: relative;
    top: 16px;
}

.datepicker {
    margin: 0 auto;
}

.datepicker thead tr{
	background: #f8f8fa;
}
.datepicker thead .dow{
	color: #000000;
}
.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active:active, .datepicker table tr td.active:hover:active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active[disabled], .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #000000;
    background-image: none;
}
#sandbox-container2  .form-control:focus {
    border: 1px solid #ccc;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #ffffff;
}

#sandbox-container2 .input-group-addon{
    padding: 6px 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #f8f8fa;
    border: 0px solid #ccc;
    border-radius: 5px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);

}



.widget button.filtrer {
    background-color: #ffffff;
    border: 3px solid #000000;
    color: #000000;
    display: inline-block;
    font-weight: 500;
	font-size: 12px;
    padding: 5px;
    width: 100%;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all .3s ease 0s;
    margin-top: 15px;
}
.widget button.filtrer:hover {
    background-color: #000000;
    color: #ffffff;
}

.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 11px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}


.footer-social-list {
    display: flex;
    align-items: center;
}

.product-main-area{
	background: #ffffff;
    padding-top: 60px;
}
.product-details-area, .giftcards-ctn{
	background: #ffffff;
}
.product-cart-area{
	background: #ffffff;
}
.checkout-area{
	background: #ffffff;
}

.description-review-shipping{
	background: #ffffff;
}

.description-review-shipping li {
    list-style: disc;
}

/*.long-description > * {
    font-size: 12px;
    margin-top: 10px;
}*/

.long-description {
    margin-top: 20px;
}

.short-description {
    background-color: #f2f3f6;
    padding: 15px;
}

.related-post-area{
	background: #ffffff;
}

.footer-logo img{
	height: 70px;
}
.main-title{
	width: auto;
	padding: 25px;
}
.main-title h1 {
    font-size: 36px;
    font-weight: 700;
}


.main-recherche{
	width: auto;
	padding: 22px;
}
.main-recherche .form-control{
	font-size: 16px;
	height: 45px;
	border: 2px solid #f3f3f3;
	width: 500px;
	background: #f8f8fa;
	box-shadow: none;
}
.main-recherche .form-control:focus {
    border: 2px solid #000000;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.main-recherche .btn-search{
	background-color: #000000;
	color: #ffffff;
	border-radius: 4px;
	font-size: 18px;
	line-height: 18px;
	height: 45px;
	border: 2px solid #000000;
}
.main-recherche .btn-search:hover{
	background-color: #000000;
	border: 2px solid #000000;
}


.product-banner-area h2{
	background-color: #000000;
	color: #ffffff;
	padding: 10px;
	width: auto;
	text-transform: uppercase;
	font-size: 32px;
}
.product-banner-area h3{
	background-color: #ffffff;
	color: #333333;
	padding: 10px;
	width: auto;
	font-size: 16px;
}
.product-top-area {
    border-bottom: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    padding-bottom: 4px;
}
.product-top-area h3{
    border-bottom: 3px solid #ebebeb;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.product-top-area .product-tablist{

}


.product-showing > p {
    padding-top: 0;
}

.green-color .header-search-cart{
	padding-top: 32px;
}


.sorting-bg-1 form select {
    background: #f8f8fa;
    border: medium none;
    box-shadow: none;
    color: #000000;
    height: 37px;
    letter-spacing: 0.4px;
    margin-right: 20px;
    padding-left: 20px;
    width: 100%
}
.product-tags ul li a {
	text-transform: uppercase;
}


.related-post-area.home .container{
	border-bottom: 1px solid #d6d6d6;
}
.product-bottom-area .product-title {
    padding: 15px 10px 20px;
	min-height: 125px;
}
.product-title > h3 {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 4px;
    font-weight: 700;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase
}
.product-title > h4 {
    color: #000000;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-top: 0;
    margin-bottom: 4px;
    font-weight: 700;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.product-title span{
	font-size: 13px;
	display: block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.product-title span.lieu{
	color: #7c7c7c
}

.product-title span.date{
	color: #7c7c7c;
	text-transform: capitalize;
	padding: 0;
}

.title-faq, .title-page {
    font-weight: 700;
    color: #222831;
    font-size: 30px;
    letter-spacing: 0.4px;
    margin: 30px 0 0;
}

.uk-subnav-pill.uk-subnav-pill--faq {
    border-bottom: 4px solid #000;
    max-width: 800px;
    margin: auto;
}

.faq-card {
    margin-top: 15px;
}

.faq-card a {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    padding: 20px;
}

.faq-card a {
    border-radius: 5px;
}

.faq-card.uk-active a {
    box-shadow: 2px 5px 42px 5px rgb(0 0 0 / 15%);
}

.faq-card img {
    max-width: 80px;
    max-height: 80px;
    margin: 0 auto 10px;
    display: block;
}

.uk-subnav-pill.uk-subnav-pill--faq a {
    padding: 10px 15px;
}

.uk-subnav-pill.uk-subnav-pill--faq li:hover a {
    background: none;
}

.title-page {
    margin-bottom: 25px;
    margin-top: 30px;
    text-transform: uppercase;
}

.description-page {
    text-align: justify;
}

.product-img > span{
	background-color: rgba(255,255,255,0.7);
	padding:0 10px
}

.homeselec .col-md-3{
	width: 100%;
}
.homeselec.owl-carousel .owl-nav div {
    color: #c5c5c5;
    font-size: 20px;
    position: absolute;
    right: 35px;
    text-align: center;
    top: -90px;
    transition: all 0.3s ease 0s;
}
.homeselec.owl-carousel .owl-nav div:hover {
    color: #000000;
}
.homeselec.owl-carousel .owl-nav div.owl-next {
    right: 20px;
}




.alaffiche .col-md-4 {
    width: 100%;
}

.alaffiche.owl-carousel .owl-nav div {
    color: #c5c5c5;
    font-size: 20px;
    position: absolute;
    right: 30px;
    text-align: center;
    top: -50px;
    transition: all 0.3s ease 0s;
}
.alaffiche.owl-carousel .owl-nav div:hover {
    color: #000000;
}
.alaffiche.owl-carousel .owl-nav div.owl-next {
    right: 15px;
}




.single-list-product {
    border-bottom: 1px solid #ebebeb;
    padding: 15px 0 10px;
    display: block;
    float:none
}


.col-table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
}

.product-main-area .single-list-product .row {
    margin-bottom: 30px !important;
}

.single-list-dec h3 {
	font-size: 20px;
    font-weight: 700;
    margin-bottom: 7px;
    text-transform: uppercase;
}
.single-list-dec h4 {
	font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #000000;
}
.single-list-dec span.lieu{
	color: #7c7c7c;
	margin-bottom: 0px;
}
.single-list-dec span.date{
	color: #7c7c7c;
	text-transform: capitalize;
	margin-bottom: 10px;
}
.single-list-product .tarifs {
	font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #7c7c7c;
    margin-top: 10px;
    text-align: center;
    min-height: 17px;
}
.single-list-product .tarifs b{
    color: #333333;
    font-size: 14px;
    margin: 0 3px;
}


.product-bottom-area button.bt-list {
    background-color: #ebebeb;
    border: medium none;
    color: #000000;
    font-weight: 500;
    line-height: 1;
    padding: 15px 3px;
    width: 100%;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all .3s ease 0s;
}
.product-bottom-area button.bt-list:hover {
    background-color: #000000;
    color: #ffffff;
}


.product-icon button.bt-list{
	width: 120px;
}


.product-bottom-area button.bt-complet {
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
    font-weight: 500;
    line-height: 1;
    padding: 15px 3px;
    width: 100%;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: default
}

.product-icon button.bt-complet{
	width: 120px;
	background-color: #000000;
	color: #ffffff;
}


.single-list-dec span{
	display: block;
	font-size: 16px;
}





.single-best-seller {
    margin-bottom: 20px;
}
.best-seller-text h3 {
    color: #000000;
    font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 0px;
	text-transform: uppercase;
}
.best-seller-text h4 {
    color: #000000;
    font-weight: 700;
	font-size: 13px;
	line-height: 18px;
	margin-bottom: 5px;
	text-transform: uppercase;
}
.best-seller-text span {
	display: block;
	font-size: 13px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.best-seller-text {
    padding-left: 0px;
}


.cart-img img{
	width: 60px
}
.cart-content {
    max-width: 130px;
}
.cart-content h3 a {
    color: #000;
    width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mobile.single-product-details{
	display: none;
}
.mobile.multi-product-details{
    display: none;
}
.mobile.image-product-details{
	display: none;
}

.desktop.single-product-details{
	display: block
}
.single-product-details > h2 {
    font-weight: 700;
}
.single-product-details > h3 {
    color: #222831;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.4px;
    margin: 5px 0;
    text-transform: uppercase;
}
.single-product-details .select-sessions select{
    display: inline-block;
    width: auto;
    height: 35px;
    border: 2px solid #1f1f1f;
    margin: 3px 0 2px;
}
.single-product-details.general{
	border-bottom: 0px solid #e1e1e1;
}
.single-product-details .orga {
    margin: 0 0 10px 0;
    color: #888888;
    letter-spacing: 0.4px;
}
.single-product-details .date {
	font-size: 18px;
	color: #7c7c7c;
    margin: 10px 0 5px 0;
}
.single-product-details .lieu {
	font-size: 18px;
	color: #7c7c7c;
    margin: 5px 0;
    text-transform: uppercase;
}
.single-product-details .ville {
	font-size: 18px;
	color: #7c7c7c;
    margin: 5px 0 15px 0;
    text-transform: uppercase;
}


.review-shipping-bottom .tab-content #infos-pratiques > p {
    letter-spacing: 0.4px;
    line-height: 28px;
}

#accordion-event .btn:focus, .btn:active:focus, #accordion-event .btn.active:focus, #accordion-event .btn.focus, #accordion-event .btn:active.focus, #accordion-event .btn.active.focus {
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
#accordion-event h5 {
	background-color: #ebebeb;
	height: 50px;
}
#accordion-event h5 .btn-link{
	color: #ffffff;
	padding: 10px 10px;
	width: 100%;
}
#accordion-event h5:hover{
	background-color: #000000;
}
#accordion-event h5 .btn-link .f-left{
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	color: #000000;
}
#accordion-event h5:hover .btn-link .f-left{
	color: #ffffff;
}
#accordion-event h5 .btn-link .f-right{
	color: #7c7c7c;
}
#accordion-event h5:hover .btn-link .f-right{
	color: #ffffff;
}
#accordion-event .card-body{
	padding: 0px;
}
#accordion-event .card-body .type{
	padding: 15px 0px;
	border-top: 1px solid #e1e1e1;
	margin-left: 0px;
    margin-right: 0px;
    line-height: 22px;
}
#accordion-event .card-body .type .number-place i{
	color: #7c7c7c;
	font-size: 20px;
}
#accordion-event .card-body .type .number-place i:hover{
	color: #000000;
}
#accordion-event .card-body .type .number-place span{
	font-size: 18px;
	font-weight: 700;
	padding: 2px 5px;
	margin: 0 5px;
    line-height: 18px;
    color: #666666;
}

.product-details-rightbar .total{
	font-size: 16px;
}

.cart-common-btn.wl-booked-btn > button{
	background-color: #28BB83;
	color: #ffffff;
	font-weight: 700;
	padding: 20px 35px;
	border: 2px solid #25946a;
    letter-spacing: 0.4px;
}
.cart-common-btn.wl-booked-btn > button:hover{
	background-color: #000000;
	color: #ffffff;
	border: 2px solid #000000;
}


.cart-common-btn.choixsurplan > a {
    background-color: #ebebeb;
    color: #000000;
    display: inline-block;
    letter-spacing: 0.4px;
    padding: 20px 35px;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid #000000;
    font-weight: 700;
}
.cart-common-btn.choixsurplan > a:hover {
    background-color: #000000;
    color: #ffffff;
    display: inline-block;
    letter-spacing: 0.4px;
    padding: 20px 35px;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid #000000;
    font-weight: 700;
}


.related-post-active.owl-carousel .owl-nav div {
    color: #c5c5c5;
    font-size: 20px;
    position: absolute;
    right: 25px;
    text-align: center;
    top: -90px;
    transition: all 0.3s ease 0s;
}
.related-post-active.owl-carousel .owl-nav div:hover {
    color: #000000;
}
.related-post-active.owl-carousel .owl-nav div.owl-next {
    right: 10px;
}


/* YOUTUBE RESPONSIVE */

.embedresize {
max-width: 560px;
margin: auto;
}

.embedresize div {
position: relative;
height: 0;
padding-bottom: 56.25%;
}

.embedresize iframe {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}


h3.basetitle {
    border-bottom: 1px solid #e5e5e5;
    color: #000;
    font-size: 25px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    width: 100%;
}
.table-content table td.product-thumbnail img{
	max-width: 140px
}
.table-content table td.product-name > span.nom1 {
	display: block;
    color: #000000;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px
}
.table-content table td.product-name > span.nom {
	display: block;
    color: #000000;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px
}
.table-content table td.product-name > span.lieu {
	display: block;
    color: #000000;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px
}
.table-content table td.product-name > span.date {
	display: block;
	margin-top: 10px;
}
.table-content table .product-price .amount {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    display: block;
}
.table-content table .product-price .amount del {
    font-size: 13px;
    margin-right: 10px;
}
.table-content table .product-price .nbbillet {
    color: #7c7c7c;
    font-weight: 500;
    letter-spacing: 1px;
}
.total-commande{
	border-top: 1px solid #ebebeb;
    display: block;
    margin-top: 69px;
    margin-bottom: 36px;
    padding-top: 57px;
}
.product-subtotal i{
	font-size: 18px;
	margin-right: 10px;
	color: #000000
}
.product-subtotal i:hover{
	color: #7c7c7c
}
.table-content table td.product-name a.info-lieu, a.info-livraison, a.info-ico{
	color: #000000;
    display: inline-block;
    font-size: 14px;
	margin-left: 5px;
}
.table-content table td.product-name a.info-lieu:hover, a.info-livraison:hover, a.info-ico:hover{
	color: #7c7c7c;
}


.ui-tooltip{
	background-color:#f8f8fa;
	outline: 0;
	box-shadow: none;
	border: 1px #f3f3f3 solid;
	font-size: 12px;
}


.login-form-container {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ddd;
    padding: 30px 40px;
    text-align: left;
}
.checkbox-form h3.client {
    border-bottom: 0 solid #e5e5e5;

}

.order-button-payment {
    margin-top: 20px;
}

.login-form-container .order-button-payment button {
    box-shadow: none;
    font-size: 14px;
    height: 40px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    width: 100%;
}
.login-form-container .order-button-payment.continuer button {
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
	text-align: center;
	cursor: pointer;
}
.login-form-container .order-button-payment.continuer button:hover {
    //background: #000000 none repeat scroll 0 0;
    background: #7c7c7c none repeat scroll 0 0;
}

.login .bt-info {
    background: #000000;
    margin-top: 10px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    border: 3px solid #000000;
    border-radius: 7px;
    padding: 5px 10px;
    max-width: 210px;
    text-align: center;
}
.login .bt-info:hover{
    color: #000000;
    background: #f2f3f6;
    border: 3px solid #f2f3f6;
}
.login .bt-info i{
    margin-right: 5px;
}


.cart_item .product-name span, .cart_item .product-total span{
	display: block
}
.cart_item .product-name span.nom1{
	font-weight: bold;
	text-transform: uppercase;
	color: #000000;
	font-size: 16px;
}
.cart_item .product-name span.nom{
	font-weight: bold;
	text-transform: uppercase;
	color: #464646;
}

.cart_item .product-total span.amount{
	font-weight: bold;
    display: inline-block;
}

.acc-nl input[type="checkbox"] {
    display: inline-block;
    height: inherit;
    margin-right: 10px;
    position: relative;
    top: 0;
    width: inherit;
}
.acc-nl strong {
    margin-right: 10px;
}


.acc-nl span{
	display: flex;
	/*margin-right: 20px;*/
}

.button-box .order-button-payment.continuer input{
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
	text-align: center;
	cursor: pointer;
	margin-top: 40px;
}
.button-box .order-button-payment.continuer input:hover {
    background: #7c7c7c none repeat scroll 0 0;
}

.button-giftcard, .button-promo {
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    margin: 0 0 20px 20px;
    padding: 0 10px;
    height: 40px;
    letter-spacing: 2px;
    font-weight: 600;
}

.inscription-select, .bank-select {
    margin-bottom: 30px;
    position: relative;
}

.inscription-select select, .bank-select select{
	background: #f8f8fa;
	border: 2px solid #eceff8;
    height: 40px;
}

.input-group-text.cb{
	display: inline-block;
	width: auto;
	margin-right: 10px
}
.cb input[type="radio"]{
	display: inline-block;
	width: auto;
	margin-bottom: 0
}
.cb img{
	height: 30px;
	margin-top: -25px;
	margin-left: 10px
}
a.cgv{
	color: #000000;
	font-weight: bold;
}
a.cgv:hover{
	color: #464646
}
img.paybox{
	height: 60px;
}

.payment-form-container input {
    margin-bottom: 20px !important;
    margin-top: 0px !important;
}

.order-button-payment.payer input{
    background: #28bc83 none repeat scroll 0 0;
    color: #ffffff;
    border: 1px solid #28bc83;
    border-radius: 3px;
	text-align: center;
	cursor: pointer;
	margin-top: 20px;
}
.order-button-payment.payer input:hover{
    background: #000000 none repeat scroll 0 0;
    border: 1px solid #000000;
}


.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
    color: #fff;
    background-color: #000000;
}
.nav-pills>li.active>a h4, .nav-pills>li.active>a:hover h4, .nav-pills>li.active>a:focus h4 {
    color: #fff;
    font-weight: bold
}
.nav-pills>li.active>a p, .nav-pills>li.active>a:hover p, .nav-pills>li.active>a:focus p {
    color: #fff;
}
.thumbnail-step {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #f8f8fa;
    border: 0px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}


.your-order-table table th, .your-order-table table td.product-name {
	text-align: left;
}

.paiement-valid{
	color: #28bc83;
	margin-right: 10px;
}

.paiement-failed{
	color: #f0506e;
	margin-right: 10px;
}

.your-order-table table tr.cart_item:hover {
	background: #f2f2f2;
}

.modal .close {
    color: white;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
	padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    background: #000000;
    color: #ffffff;
 }
.modal-header h5 {
    color: #ffffff;
 }
.modal-footer {
	 background: #f8f8fa;
}

.blog-sidebar a i{
	color: #000000;
	margin-right: 10px;
}
.blog-sidebar-menu > li.active a{
	color: #000000;
}
.blog-sidebar .sidebar-widget {
	background-color: #fff;
    padding: 30px
}
.commande-table .table>tbody>tr>td {
	vertical-align: middle;
}
.commande-table a {
	color: #000000;
}
.commande-table a:hover {
	color: #7c7c7c;
	text-decoration: underline
}
.commande-table .icon a{
	color: #000000;
}
.commande-table .icon a:hover{
	color: #7c7c7c;
}
.commande-table .icon i {
	font-size:22px;
	margin-right: 15px;
}

.blog-wrapper .cart-common-btn > button,
.blog-wrapper .cart-common-btn > a {
	width: 100%;
	text-align: center;
    font-weight: bold;
}
.button-box .order-button-payment.continuer input.motdepasse {
	margin-top: 10px;
	margin-bottom: 20px;
}

.adresses .checkout-form-list {
	margin-bottom: 0px
}

.pagination>li>a, .pagination>li>span {
	color: #000000;
}

.commande .cart-common-btn i{
	margin-right: 10px;
	color: #000000;
}
.commande .cart-common-btn i{
	margin-right: 10px;
	color: #000000;
}
.commande .cart-common-btn:hover i{
	color: #ffffff;
}

.cart-download-eticket-btn {
    display: inline-block;
    margin-top: 10px;
    background-color: #000;
    padding: 5px 10px;
    color: #fff !important;
    border-radius: 5px;
}
.cart-download-eticket-btn i {
    margin-right: 5px;
}


.black-bg {
    background: #000000;
}
.footer-shop-2 .copyright > p a {
    color: #e6e6e6;
}


.modal {
    z-index: 99999;
}
.absolute-bar {
    position: absolute;
    transition: all 0.5s ease 0s;
    width: 100%;
    z-index: 999;
}

.commande-table td span{
	display: block
}
.commande-table td span.event-name-mobile{
	display: none;
}

.filter-area .calendar-mobile{
	display: none;
}
.product-bottom-area button.bt-list.mobile {
	display: none;
}


.header-search.newsletter{
	display: none;
}

.number-place {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.number-place .quantity {
    background-color: white;
    border: none;
    text-align: center;
    width: 30px;
    font-size: 18px;
    font-weight: 700;
    padding: 2px 5px;
    margin: 0 5px;
    line-height: 18px;
    color: #666666;
    padding-bottom: 5px;
}

.back-home-btn a{
    background-color: #ebebeb;
    color: #000000;
    display: inline-block;
    letter-spacing: 0.4px;
    padding: 22px 35px 19px;
    text-transform: uppercase;
    border-radius: 5px;
}

.cart-total .radio {
    display: none;
}

.cart__item-delete {
    outline: none!important;
    color: black;
}

.cart__item-delete:hover {
    outline: none!important;
    color: black;
}

.calendar-input-hour {
    background: #dedede;
    padding: 4px 4px 4px 8px;
    border-radius: 2px;
    display: inline-block;
}

.calendar-input-hour input[type=radio] {
    visibility: hidden;
    width: 0;
    height: 0;
}

.calendar-hours {
    margin-bottom: 20px;
}

/*#_tnl_popin ul {
    padding-left: 0;
}*/
#_tnl_popin li {
    list-style: disc inside;
}

.fa-user.authenticated {
    position: relative;
}

.fa-user.authenticated:after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 12px;
    height: 12px;
    background-color: #28bb83;
    border-radius: 50%;
}


/*********************************************/

#passwordCheckerList {
    margin-left: 15px;
    margin-top: 15px;
}
#passwordCheckerList strong {
    font-weight: 600;
}
#passwordCheckerList > * {
    font-size: 14px;
}

/*** CHOIX SUR PLAN ***/

.panel-heading {
        &.panel-bar {
            color: #333333;
            background-color: #ebebeb;
            border-color: #ebebeb;
            height: 50px;
            padding: 8px 16px;
            flex-grow: 0;
            .panel-title {
                line-height: 34px;
                text-transform: none;
                font-weight: 400;
                font-size: 1.1em;
                margin: 0!important;
                padding: 0;
                white-space: nowrap;
                &.category {
                    font-family: inherit;
                    a {
                        color: #333333;
                        font-weight: 600;
                    }
                }
            }
        }
        span.icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: .2em;
            line-height: 34px;
        }

        .chevron {
            margin-left: 20px;
            display: none;
            @media only screen and (max-width: 992px) {
                display: inline-block;
            }
        }

    }

.filter--container {
    h3 {
        margin-bottom: 20px !important;
        font-size: 16px!important;
    }
}

/*** POOL ***/

#poolCustomersTable_filter {
    margin-bottom: 20px;
    float: left;
}


@media (min-width: 768px) and (max-width: 992px) {

	.header-cart, .header-search {
	    margin-right: 0;
	}

    .single-list-dec h3 {
        font-size: 18px;
    }
    .single-list-dec h4 {
        font-size: 16px;
    }
    .single-list-dec span {
        font-size: 14px;
    }

    .single-list-title > span {
        margin-top: 10px;
    }

}

@media (max-width: 992px){

    .page-main-area {
        padding-left: 40px;
        padding-right: 40px;
    }
    .your-order {
        margin-bottom: 40px;
    }

    .single-list-title > h3 {
        margin-top: 20px;
    }
    .single-list-stock > h4 {
        margin-top: 20px;
    }

	.pt-30 {
	    padding-top: 15px;
	}
	.pb-100 {
	    padding-bottom: 20px;
	}

	.container {
	    padding: 0;
	}

	.header-style-3 .header-top {
	    background: #FFFFFF;
	    padding: 15px 10px;
	}
	.header-logo img {
        height: 70px;
	}

    .stick .header-logo img {
        height: 60px;
    }

	.header-style-3 .header-top.stick {
	    padding: 10px 10px;
	    width: 100%;
	    margin: 0;
	}

	.menu-area.transparent-bar.stick {
	    background-color: #000;
	    border-bottom: 0 solid #4a90e2;
	    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
	    position: fixed;
	    top: 80px;
	    width: 100%;
	    padding: 0 0 5px 0;
	    margin: 0
	}



	/*.main-menu {
	    display: block;
	}
	.main-menu .title {
	    text-align: right;
        padding-top: 0;
        max-height: 70px;
	}
	.main-menu .title img {
		height: 24px;
	    vertical-align: top;
	    margin-top: 22px;
	}*/
	.bt-top{
		display: none;
	}
	.menu-area {
	    background: #000000;
	    padding: 5px 0;
	}



	.search-top {
	    padding: 5px 0 0 8px;
	    width: calc(100% - 180px);
	}
	.search-top form#searchbox input {
		height: 30px;
	    padding: 5px;
	    border: 3px solid #ffffff;
	    border-radius: 5px;
	    margin: 0 5px 0 0;
	    font-size: 12px;
	    width: calc(100% - 50px);
	}
	.search-top button {
	    font-size: 14px;
	    line-height: 17px;
	    height: 34px;
	    padding: 5px 10px;
	    border-radius: 5px;
	    border: 3px solid #ffffff;
	    font-weight: 700;
	}
	.search-top button:hover {
	    font-size: 14px;
	    line-height: 17px;
	    height: 34px;
	    padding: 5px 10px;
	    border-radius: 5px;
	    border: 3px solid #f2f3f6;
	    font-weight: 700;
	}

	.icon-top {
	    margin-right: 8px;
	}

	.icon-top .link {
	    margin-right: 0;
	    margin-top: 3px;
	}
    .cart-icon-container {
        margin-right: 4px !important;
        margin-left: 4px !important;

    }
	.common-style {
	    display: inline-block;
	    margin-left: 4px;
	}

    .cart-countdown-container {
        margin-left: 4px !important;
    }
	.common-style > a {
	    color: #ffffff;
	    font-size: 16px;
	    line-height: 1rem;
	    margin: 0;
	    padding: 0;
	}
	.header-cart a span.shop-count {
	    font-size: 12px;
        right: -7px;
	}
	.timer-cart {
	    margin: 6px 0 0 15px;
	    background: #f7f6f8;
	    color: #7c7c7c;
	    font-size: 12px;
	    padding: 7px 4px 7px 4px;
	    border-radius: 5px;
	    font-weight: 500;
	    letter-spacing: 1px;
	    height: 32px;
	    position: relative;
	    top: -1px;
        width: 70px;
	}
	.timer-cart i {
	    margin-right: 4px;
	    font-size: 16px;
	    color: #000000;
	}

	.content {
	    padding-top: 145px;
	    background: #f2f3f6;
	}

    .content.stick {
        padding-top: 128px;
    }

	.filter-area .container {
	    margin-top: 14px;
	}
	.product-sidebar-area{
		display: none
	}

	.product-top-area {
	    margin-top: 0;
	    display: none;
	}
	.single-list-dec {
	    margin-top: 0px;
	}
	.single-list-product {
	    margin-bottom: 0px;
	    padding: 10px
	}


	.product-bottom-area button.bt-list.mobile {
		display: block;
	    background-color: #000000;
	    border: medium none;
	    color: #ffffff;
	    font-size: 11px;
	    font-weight: 500;
	    line-height: 1;
	    padding: 7px 3px;
	    width: 100%;
	    text-transform: uppercase;
	    border-radius: 5px;
	    transition: all .3s ease 0s;
	    width: 120px;
	}



	.single-list-product .col-xs-3 {
	    width: 25%;
	    padding: 0
	}
	.single-list-product .col-xs-9 {
	   width: 70%;
	   padding-right: 0;
	}
	.single-list-product .col-xs-12 {
	   display: none;
	}

	.single-banner.banner-mrg {
	    margin: 0;
	}


	.product-bottom-area .tab-content .grid, .product-bottom-area .tab-content .grid.active {
		display: none
	}
	.product-bottom-area .tab-content .list, .product-bottom-area .tab-content .list.active {
		display: block
	}

	.banner-area.pt-100 {
        padding-top: 40px;
	}
	.banner-area .row {
	    margin: 0;
	}
	.single-list-product .row {
	    margin: 0;
	}
	.single-list-product .row .col-md-12 {
	    padding: 0;
	}
	.product-main-area .row {
	    margin: 0;
	}
	.product-main-area .row .col-sm-12.col-xs-12 {
	    padding: 0;
	}

	.product-details-area.pt-60, .giftcards-ctn.pt-60 {
		padding-top: 15px;
	}
	.product-details-area .container, .giftcards-ctn .container {
	    padding: 0 10px;
	}

	.mobile.image-product-details {
		display: inline;
		margin-bottom: 0;
		width: 90px;
		margin-right: 10px

	}
	.mobile.image-product-details img {
		width: 80px;
		vertical-align: top;
	}
	.mobile.single-product-details {
		display: inline-block;
		margin-bottom: 5px;
		width: calc(100% - 110px);
	}
    .mobile.multi-product-details {
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 15px;
        width: 100%;
    }
    .mobile.multi-product-details .select-sessions-title {
        margin-bottom: 5px;
    }
	.product-details-leftbar {
		display: none;
	}


	.desktop.single-product-details {
		display: none
	}
	.product-details-content .col-md-9 {
		padding: 10px;
	}
	.product-details-content .f-right.plan {
		float: none;
	    text-align: center;
	    margin: 10px auto;
	}

	.product-details-rightbar {
	    margin-top: 0;
	}
	.product-details-rightbar .ptb-20 {
	    padding: 0;
	}


	#accordion-event .card-body .type-mob {
		text-align: left;
		width: calc(100% - 120px);
		margin: 0;
		float:left;
	}
	#accordion-event .card-body .type .col-md-3.number-place.text-right {
		text-align: center;
		margin-top: 10px;
		width: 120px;
		float: right;
	}

	.product-details-rightbar .total {
		margin-top: 10px;
	}
	.update-checkout-cart.f-right {
	    display: block;
	    float: left;
	    margin-top: 0;
	    width: 100%;
	}
	.update-cart.cart-common-btn {
	    display: inline-block;
	    margin-left: 0;
	    width: 100%;
	}
	.cart-common-btn > button,
    .cart-common-btn > a {
	    padding: 12px 23px 9px;
	    width: 100%;
	    text-align: center;
	}
	.description-review-shipping {
	    background: #ffffff;
	    padding: 20px 10px;
	}
	.related-post-area {
	    padding: 10px;
	}
	.related-post-title > h2 {
	    border-bottom: 1px solid #d6d6d6;
	    color: #404040;
	    font-size: 18px;
	    line-height: 24px;
	    letter-spacing: 0.4px;
	    margin-bottom: 46px;
	    padding-bottom: 20px;
	    text-transform: uppercase;
	}
	.related-post-active.owl-carousel .owl-nav div{
		right: 35px;
	}
	.related-post-active.owl-carousel .owl-nav div.owl-next {
	    right: 20px;
	}


	.product-cart-area, .checkout-area{
		padding: 20px 10px;
	}
	.product-cart-area .nav>li, .checkout-area .nav>li{
		position: relative;
	    display: table-cell;
	    width: 1%;
	}
	.product-cart-area .nav>li>a, .checkout-area .nav>li>a {
	    position: relative;
	    display: block;
	    padding: 10px 10px;
	}
	.product-cart-area .thumbnail-step, .checkout-area .thumbnail-step{
		text-align: center
	}

	.list-group-item-text {
		margin-bottom: 0;
		line-height: 1.3;
		font-size: 11px;
	}

	.cb img {
	    height: 26px;
	    margin-top: 0;
	    margin-left: 5px;
	}


	.product-cart-area .table-content table tr{
	    margin-bottom: 30px;
		/*display: inline-block;*/
	}

	.product-cart-area .table-content table td {
    padding-top: 5px;
    width: calc(100% - 120px);
    display: block;
    float: left;
	}
	.product-cart-area .table-content table td.product-thumbnail{
		display: none;
	}
	.product-cart-area .table-content table td.product-subtotal.product-subtotal{
		width: 100%
	}
	.product-cart-area .table-content table td.product-cart-icon.product-subtotal{
	    width: 110px;
	    display: block;
	    margin: 10px 0 20px 0;
	    float: right;
	    z-index: 22;
	    position: absolute;
	    right: 0;
	}


	.table-content table .product-price span.amount {
	    display: inline-block;
	    margin-right: 10px
	}
	.table-content table td.product-name > span.lieu {
	    display: inline-block;
	    margin-right: 10px
	}
	.table-content table td.product-name a {
	    margin-bottom: 0;
	}
    .table-content table td.product-name > span {
        font-size: 14px;
    }
    .table-content table td.product-name > span.nom1 {
        font-size: 16px;
    }
	.table-responsive {
	    border: medium none;
	    padding-bottom: 10px;
	    margin-bottom: 5px;
	}
	.total-commande {
		border-top: 1px solid #ebebeb;
	    display: block;
	    margin-top: 0;
	    margin-bottom: 36px;
	    padding-top: 20px;
	}
	.cart-total > h3 {
	    background-color: #000000;
	    text-align: center;
	    padding: 10px;
	    text-transform: uppercase;
	    border-radius: 5px;
	}
	.cart-shipping.f-left{
		float: none;
		margin-bottom: 5px;
	}
	.cart-total ul {
    	padding: 10px 0 15px;
	}
	.cart-total ul li.order-total {
	    border-bottom: 1px solid #ebebeb;
	    margin-bottom: 15px;
	    padding-bottom: 15px;
	}

	.checkout-area .pb-20{
		padding-bottom: 0
	}
	.modal-dialog {
	    position: relative;
	    width: auto;
	    margin: 20px;
	}


	.blog-sidebar{
		display: none;
	}
	.blo2-area.ptb-50{
		padding: 0
	}
	.mon-cpte{
		position: absolute;
	    z-index: 2;
	    top: -37px;
	    left: 37px;
	    font-weight: bold;
	    text-transform: uppercase;
	    font-size: 16px;
	}
	.mean-container a.meanmenu-reveal {
	    top: -40px;
	    right: 20px;
	}
	.mobile-menu .mean-nav ul.menu-overflow {
	    height: auto;
	    margin-top: 0;
	    overflow-y: auto;
	    margin-bottom: 20px;
	}
	.mean-container .mean-nav ul li a {
	    font-size: 14px;
	    font-weight: normal;
	}
	.mean-container .mean-nav ul li a i{
	    margin-right: 10px;
	}
	.sidebar-title {
	    font-size: 20px;
	    margin-bottom: 50px;
	}
	.sidebar-title.pt-60{
		padding-top: 30px;
	}


	#accordion-event h5 .btn-link {
	    color: #ffffff;
	    padding: 10px 10px;
	    width: 100%;
	    min-height: 50px;
	}

	.blo2-area .row{
		margin: 0
	}
	.blog-wrapper .cart-common-btn > button,
    .blog-wrapper .cart-common-btn > a{

	    margin-bottom: 10px;
	}


	.commande-table td.event-name{
		display: none
	}

	.commande-table td span.event-name-mobile{
		display: block;
	}

	.header-search.newsletter{
		display: inline-block
	}

	.input-group-text.cb img {
	    vertical-align: super;
	}

    .uk-subnav-pill.uk-subnav-pill--faq {
        border-bottom: none;
    }

}


@media (max-width: 769px){
    .bgdtitle {
        padding: 0 20px;
        font-size: 18px;
    }
    .single-list-dec h3 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .single-list-dec h4 {
        font-size: 13px;
        margin-bottom: 5px;
    }
    .single-list-dec span {
        font-size: 12px;
    }
    .single-list-dec h3, .single-list-dec h4, .single-list-dec span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
    .single-list-title > span {
        margin-top: 5px;
    }
    .single-product-details h2 {
        font-size: 16px;
    }
    .single-product-details h3 {
        font-size: 16px;
    }
    .single-product-details .date {
        margin: 5px 0 5px 0;
    }
    .single-product-details .lieu, .single-product-details .date {
        font-size: 12px;
    }
    .mobile.embedresize {
        display: block;
        margin: 30px auto 20px;
    }
    .single-list-title > span {
        margin-top: 5px;
    }
    .single-list-title > h3, .single-list-stock > h4 {
        margin-top: 10px;
    }
}


/*****calendrirer pour mobile *****/


@media (max-width: 480px){


	.filter-area .widget #sandbox-container2{
		display: none;
	}
	.filter-area .calendar-mobile{
		display: block;
	}
	.calendar-mobile .input-group-addon {
	    padding: 6px 12px;
	    font-size: 12px;
	    font-weight: normal;
	    line-height: 1;
	    color: #555;
	    text-align: center;
	    background-color: #f8f8fa;
	    border: 0 solid #ccc;
	    border-radius: 5px;
	    box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
	}
	.filter-area .calendar-mobile{

	    height: 34px;
	    margin: -15px 10px 5px 10px
	    /*display: inline-block;
	    position: relative;
	    bottom: -16px;*/
	}
	.filter-area .calendar-mobile input{
	    height: 34px;
	    font-size: 12px;
	}

    .header-style-3 .header-top {
        padding: 7px 10px;
    }

    .header-style-3 .header-top.stick {
        padding: 5px 10px;
    }

    .menu-area.transparent-bar.stick {
        top: 70px;
    }

    .content {
        padding-top: 129px;
    }

    .content.stick {
        padding-top: 119px;
    }

    .product-details-area {
        padding-bottom: 10px;
    }

    .cart-common-btn.choixsurplan > a {
        padding: 10px 20px;
    }
}



/*@media (max-width: 1024px) and (min-width: 545px){

	.main-menu .title img {
	    height: 40px;
	    margin-top: 12px;
	}

}
@media (max-width: 320px){
	.main-menu .title img {
	    height: 20px;
	    vertical-align: top;
	    margin-top: 26px;
	}
}*/


/** BOOTSTRAP MULTISELECT **/

.multiselect-container {
    padding-top: 6px;
    padding-bottom: 6px;
}

.multiselect-container input {
    height: auto;
    width: auto;
    padding-left: 0;
    margin-top: 0;
}

.multiselect-container .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem .25rem 1.2rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.multiselect-container .multiselect-all .form-check, .multiselect-container .multiselect-group .form-check, .multiselect-container .multiselect-option .form-check {
    padding: 0 5px 0 0;
}
.multiselect-container .multiselect-all .form-check-label, .multiselect-container .multiselect-group .form-check-label, .multiselect-container .multiselect-option .form-check-label {
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
}

.multiselect-native-select .btn-group .multiselect {
    background-image: none;
    background: #f8f8fa;
    border: 0;
    outline: 0;
    border-radius: 5px;
    color: #333;
    font-size: 12px;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
    width: 170px;
    text-align: right;
    position: relative;
}

.multiselect-native-select .btn-group .multiselect .multiselect-selected-text {
    position: static;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.multiselect-native-select .btn-group.show {
    display: inline-block!important;
}

.multiselect-native-select .btn-group .multiselect .bs-caret {
    margin-left: 5px;
    position: absolute;
    right: 10px;
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


#loader_switch_session {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #ffffff;
    z-index: 999;
    opacity: .9;
    display: none;
}
#loader_switch_session > div {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}
#loader_switch_session > div > div {
    margin: 0 auto;
}
#loader_switch_session .lds-ring {
    float: left;
}
#form_add_basket {
    position: relative;
    margin-right: 15px;
}
.payment-bloc + .payment-bloc {
    margin-top: 30px;
}

.holders .event-form-step {
    display: none;
}
.holders .event-form-step.active {
    display: block;
}

.cross-selling-button:hover {
    color: black;
    border: 1px solid black;

}



.table-insurance {
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #f3f3f3;
    padding: 10px;
}

.table-insurance .tfoot .td {
    font-size: 14px;
    vertical-align: top;
    padding-top: 18px;
    padding-bottom: 20px;
}

.insurance-body-cell {
    padding: 30px 0 30px 30px;
    width: 630px;
}

.insurance-body-cell .insurance-body {
    padding-right: 30px;
}

.insurance-body .description {
    font-size: 14px;
    line-height: 17px;
}

.insurance-body .mention {
    margin-top: 8px;
    font-size: 11px;
    line-height: 15px;
}

.insurance-body .mention .a {
    color: #000;
    text-decoration: underline;
}

.insurance-quantity {
    font-weight: bold;
    font-size: 13px;
    text-align: right;
}

.insurance-form {
    padding-right: 30px;
    margin-top: 10px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    /* position: absolute; */
    height: 15px;
    margin-top: 4px\9;
    margin-left: -10px;
    position: relative;
    min-height: 20px;
}

.insurance-error {
    display: none;
    margin-top: 20px;
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .insurance-body-cell {
        display: block;
        width: 100%;
    }
    .insurance-quantity-cell {
        display: block;
        width: 100%;
        padding: 0 30px 20px;
    }

    .insurance-form {
        text-align: left;
    }

    .insurance-form .checkbox label, .radio label {
        padding: 0;
    }

    .insurance-quantity {
        text-align: left;
    }
}

@media (max-width: 1450px) {
    .insurance-body-cell {
        width: 350px;
    }
}




