/*-----------------------------------------------------------------------------------

    Template Name: Ately - Minimal Portfolio Ecommerce Template
    Description: This is html5 template
    Author: HasTech
    Version: 1.0

-----------------------------------------------------------------------------------

    [Table of contents]

    1. Template default css (assets/css/shortcode/bundle.css)
    2. Header style
    3. Slider style
    4. Portfolio style
    5. Brand style
    6. Testimonials style
    7. Footer style
    8. Shop style
    9. Banner style
    10. Company page
    11. About page
    12. Product page
    13. Product sidebar
    14. product details
    15. Shopping cart
    16. Contact us
    17. Checkout page
    18. Login page
    19. Blog style
    20. Blog sidebar style
    21. Blog details

-----------------------------------------------------------------------------------*/

@font-face {
    font-family: 'Artely Inks PERSONAL USE';
    src: url('../../../common/fonts/ArtelyInksPERSONALUSE.eot');
    src: url('../../../common/fonts/ArtelyInksPERSONALUSE.eot?#iefix') format('embedded-opentype'), url('../../../common/fonts/ArtelyInksPERSONALUSE.woff') format('woff'), url('../../../common/fonts/ArtelyInksPERSONALUSE.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/*-------- 2. Header style --------*/

/* home 1 header */

.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-social.home1 {
    padding-top: 8px;
}
.header-social ul li {
    display: inline-block;
    margin-right: 25px;
}
.header-social ul li:last-child {
    margin-right: 0px;
}
.header-social ul li a {
    color: #000;
    font-size: 16px;
}
.header-social ul li a:hover {
    color: #fea1ac;
}

.cart-icon-container {
    margin-left: 14px !important;
}

.cart-summary-container {
    display: flex;
    align-items: center;
}

.shop-count {
    font-family: Poppins,sans-serif;
}

.cart-countdown-container {
    margin-left: 25px !important;
}

.common-style {
    display: inline-block;
    margin-left: 14px;
}
.common-style:first-child {
    margin-left: 0px;
}
.logo-shop {
    display: none;
    float: left;
}
.common-style > a {
    color: #010101;
    font-size: 16px;
}
.common-style:hover > a {
    color: #fea1ac;
}
.header-cart {
    position: relative;
}
.header-cart a span.shop-count {
    color: #fea1ac;
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    right: -17px;
    top: -3px;
}
.header-bar.common-style {
    margin-left: 30px;
}
/* shopping cart */

.header-search-cart {
    padding-top: 7px;
}
.header-cart > ul {
    background: #fff none repeat scroll 0 0;
    display: none;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 140%;
    width: 270px;
    z-index: 9999;
    box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
}
.header-cart ul li {
    margin-bottom: 20px;
    overflow: hidden;
}
.cart-img {
    float: left;
}
.cart-content {
    float: left;
    padding-left: 15px;
    text-align: left;
}
.cart-content h3 a {
    color: #000;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
}
.cart-content h3 a:hover {
    color: #fea1ac;
}
.cart-content span {
    color: #666;
    display: block;
    margin-top: 5px;
    letter-spacing: 1px;
}
span.cart-price {
    color: #666;
}
.cart-del {
    color: #666;
    cursor: pointer;
    float: right;
    font-size: 15px;
    transition: all .3s ease 0s;
}
.cart-del:hover {
    color: #fea1ac;
}
.header-cart ul li:last-child {
    margin: 0;
}
.header-cart ul li {
    margin-bottom: 20px;
    overflow: hidden;
}
.checkout a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #666;
    color: #000;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 30px;
    margin-right: 0;
    padding: 6px 0 4px;
    text-align: center;
    text-transform: uppercase;
}
.checkout a:hover {
    background-color: #fea1ac;
    border: 1px solid #fea1ac;
    color: #fff;
}
.green-color .checkout a:hover {
    background-color: #7c7c7c;
    border: 1px solid #7c7c7c;
    color: #fff;
}
.green-color .cart-content h3 a:hover,
.green-color .cart-del:hover {
    color: #7c7c7c;
}
.shipping {
    display: block;
    overflow: hidden;
}
.shipping span {
    color: #333;
    font-weight: normal;
    letter-spacing: 1px;
}
hr.shipping-border {
    margin: 10px 0;
}
/* search */

.header-search {
    position: relative;
}
.toogle-content {
    display: none;
    position: absolute;
    right: 0;
    z-index: 9;
}
form#searchbox input {
    background: #fff none repeat scroll 0 0;
    color: #333;
    display: inline;
    height: 40px;
    padding: 5px 40px 5px 10px;
    position: relative;
    top: 100%;
    width: 300px;
    z-index: 1;
    box-shadow: 0 0px 5px rgba(85, 85, 85, 0.2);
    border: none;
}
form#searchbox input::-moz-placeholder {
    color: #333;
    font-size: 14px;
    opacity: 1;
}
form#searchbox input::-webkit-placeholder {
    color: #333;
    font-size: 14px;
    opacity: 1;
}
.button-search {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    font-size: 0;
    padding: 0;
    position: absolute;
    right: 12px;
    top: 9px;
    z-index: 9;
}
.button-search::before {
    color: #000;
    content: "\f002";
    display: block;
    font-family: "FontAwesome";
    font-size: 14px;
    position: absolute;
    right: 14px;
    text-align: center;
    top: 3px;
    width: 100%;
}
/* main menu */

.header-bottom {
    padding: 20px 0 25px;
}
/*.main-menu nav > ul > li {
    display: inline-block;
    margin: 0 18px;
    position: relative;
}
.main-menu nav > ul > li > a {
    color: #333333;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 0.4px;
    padding: 0 0 15px;
    text-transform: capitalize;
}*/
/*.main-menu nav > ul > li:hover > a,*/
.sidebar-mega-menu nav > ul > li:hover > a {
    color: #fea1ac;
}
/*.company-header .main-menu nav > ul > li:hover > a {
    color: #76c1e9;
}*/
.company-header .header-social ul li a:hover {
    color: #76c1e9;
}
.company-header .common-style:hover > a {
    color: #76c1e9;
}
/*.main-menu nav > ul > li > ul {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: -23px;
    opacity: 0;
    padding: 10px;
    position: absolute;
    text-align: left;
    top: 120%;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 200px;
    z-index: 999;
}
.main-menu nav > ul > li > ul > li {
    display: block;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease 0s;
}
.main-menu nav > ul > li > ul > li > a,
.sidebar-mega-menu ul li ul li a {
    color: #333;
    display: block;
    font-size: 14px;
    padding: 6px 15px;
    text-transform: capitalize;
}*/
/*.main-menu nav > ul > li > ul > li:hover > a,*/
.sidebar-mega-menu nav > ul > li > ul > li:hover > a {
    color: #fea1ac;
}
/*.green-color .main-menu nav > ul > li > ul > li:hover > a,
.green-color .main-menu nav > ul > li > .mega-menu > ul > li:hover a,
.green-color .main-menu nav > ul li ul.sub-menu li:hover > a {
    color: #7c7c7c;
}
.main-menu nav > ul > li:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}*/
/* sub menu */

/*.home-six-wrapper .main-menu nav > ul > li > ul > li > ul,*/
.home-six-wrapper .sidebar-mega-menu nav > ul > li > ul > li > ul {
    right: 100%;
    left: inherit;
}
/*.home-six-wrapper .main-menu nav > ul > li > ul > li > a,*/
.home-six-wrapper .sidebar-mega-menu ul li ul li a {
    text-align: right;
}
/*.home-six-wrapper .main-menu nav > ul > li > ul > li > a span,*/
.home-six-wrapper .sidebar-mega-menu ul li ul li a span {
    margin-left: 0;
}
/*.home-six-wrapper .main-menu nav > ul > li > .mega-menu > ul > li.menu-title,*/
.home-six-wrapper .sidebar-mega-menu nav > ul > li > .mega-menu > ul > li.menu-title {
    padding-right: 13px;
    padding-left: inherit;
    text-align: right;
}
/*.home-six-wrapper .main-menu nav > ul > li > .mega-menu > ul > li.menu-title::before,*/
.home-six-wrapper .sidebar-mega-menu nav > ul > li > .mega-menu > ul > li.menu-title::before {
    right: 13px;
}
/*.main-menu nav > ul > li > ul > li > ul,*/
.sidebar-mega-menu nav > ul > li > ul > li > ul {
    left: 100%;
    margin-top: -1px;
    opacity: 0;
    position: absolute;
    top: 20px;
    visibility: hidden;
    transition: all .3s ease 0s;
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    width: 200px;
    z-index: 999;
    padding: 10px;
}
/*.main-menu nav > ul > li > ul > li:hover > ul,*/
.sidebar-mega-menu nav > ul > li > ul > li:hover > ul {
    top: 0;
    opacity: 1;
    visibility: visible;
}
/*.main-menu nav > ul li ul.sub-menu li {
    display: block;
    position: relative;
}
.main-menu nav > ul li ul.sub-menu li > a {
    color: #333;
    display: block;
    font-size: 14px;
    padding: 6px 15px;
    text-transform: capitalize;
}
.main-menu nav > ul li ul.sub-menu li > a span {
    float: right;
}
.main-menu nav > ul li ul.sub-menu li:hover > a,*/
.sidebar-mega-menu nav > ul li ul.sub-menu li:hover > a {
    color: #fea1ac;
}
/* mega menu */

/*.main-menu nav > ul > li > .mega-menu {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: -23px;
    opacity: 0;
    padding: 14px 10px 10px;
    position: absolute;
    text-align: left;
    top: 120%;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    z-index: 999;
}*/
/*.main-menu nav > ul > li > .width-col3,*/
.sidebar-mega-menu nav > ul > li > .width-col3 {
    width: 550px;
}
/*.main-menu nav > ul > li:hover .mega-menu {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.main-menu nav > ul > li > .mega-menu ul.single-mega-col3,*/
.sidebar-mega-menu nav > ul > li > .mega-menu ul.single-mega-col3 {
    display: inline-block;
    float: left;
    width: 27.333%;
}
.single-mega-col3 {
    margin-right: 30px;
}
.single-mega-col3:last-child {
    margin-right: 0;
}
/*.main-menu nav > ul > li > .mega-menu > ul > li,*/
.sidebar-mega-menu nav > ul > li > .mega-menu > ul > li {
    display: block;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease 0s;
}
/*.main-menu nav > ul > li > .mega-menu > ul > li.menu-title,*/
.sidebar-mega-menu nav > ul > li > .mega-menu > ul > li.menu-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    padding-left: 13px;
    position: relative;
    text-transform: uppercase;
    position: relative;
}
/*.main-menu nav > ul > li > .mega-menu > ul > li.menu-title::before,*/
.sidebar-mega-menu nav > ul > li > .mega-menu > ul > li.menu-title::before {
    background: #000 none repeat scroll 0 0;
    bottom: -5px;
    content: "";
    height: 1px;
    position: absolute;
    transition: all 0.3s ease 0s;
    width: 30px;
}
/*.main-menu nav > ul > li > .mega-menu > ul > li > a {
    color: #333;
    display: block;
    font-size: 14px;
    padding: 6px 15px;
    text-transform: capitalize;
}*/
/*.main-menu nav > ul > li > .mega-menu > ul > li:hover a,*/
.sidebar-mega-menu nav > ul > li > .mega-menu > ul > li:hover a {
    color: #fea1ac;
}
/* sidebar menu */

.sidebar-mega-menu {
    background: #000 none repeat scroll 0 0;
    border-left: 1px solid #fff;
    left: -300px;
    position: fixed;
    top: 0;
    transition: all 0.4s ease 0s;
    width: 317px;
    z-index: 9999;
    height: 818px;
    padding: 210px 0px 210px 100px;
}
.sidebar-mega-menu.open {
    left: 170px;
}
.sidebar-mega-menu.sidebar-mega-menu2 {
    left: -400px;
    border-left: medium none;
}
.sidebar-mega-menu.sidebar-mega-menu2.open {
    left: 0px;
}
.side-menu-icon.sidebar-menu-active {
    position: relative;
    z-index: 999999;
}
.sidebar-mega-menu.sidebar-mega-menu3 {
    background: #000 none repeat scroll 0 0;
    border-left: none;
    right: -400px;
    left: inherit;
    padding: 210px 100px 210px 0px;
    text-align: right;
    z-index: 99999;
}
.sidebar-menu-active.menu-icon-style {
    position: absolute;
    top: 30px;
}
.sidebar-menu-active.menu-icon-style a {
    font-size: 22px;
    color: #fff;
}
.sidebar-menu-active.menu-icon-style a:hover {
    color: #7c7c7c;
}
.sidebar-mega-menu.sidebar-mega-menu3.open {
    right: 0px;
    left: inherit;
}
.sidebar-mega-menu.sidebar-mega-menu3 ul li a span {
    float: left;
    margin-left: 20px;
    margin-right: 0;
}
.sidebar-mega-menu.sidebar-mega-menu3 nav > ul > li > ul,
.sidebar-mega-menu.sidebar-mega-menu3 nav > ul > li > .mega-menu {
    right: 110%;
    left: inherit;
}
.sidebar-mega-menu.sidebar-mega-menu3 nav > ul > li:hover > ul,
.sidebar-mega-menu.sidebar-mega-menu3 nav > ul > li:hover .mega-menu {
    left: inherit;
    right: 100%;
}
.sidebar-mega-menu.sidebar-mega-menu3 nav > ul > li > ul > li:hover > ul {
    opacity: 1;
    top: 0;
    visibility: visible;
}
.transparent-bar.stick {
    background-color: #000;
    border-bottom: 0 solid #4a90e2;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
    position: fixed;
    top: 0;
    width: 100%;
}
.transparent-bar.white-bar.stick {
    background-color: #fff;
}
.header-style-6 .header-top.stick {
    padding: 10px 75px;
}
.header-style-3 .header-top.stick {
  padding: 10px 90px;
}
.sidebar-mega-menu ul li {
    padding-bottom: 38px;
    position: relative;
}
.sidebar-mega-menu ul li:last-child {
    padding-bottom: 0px;
}
.sidebar-mega-menu ul li a {
    color: #fff;
    display: block;
    font-size: 24px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
}
.sidebar-mega-menu ul li a span {
    float: right;
    margin-right: 20px;
}
.sidebar-mega-menu nav > ul > li > ul {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: 110%;
    opacity: 0;
    padding: 10px;
    position: absolute;
    text-align: left;
    top: 0;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 200px;
    z-index: 99999;
}
.sidebar-mega-menu ul li ul li {
    padding-bottom: 0px;
}
.sidebar-mega-menu nav > ul > li > .mega-menu {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: 110%;
    opacity: 0;
    padding: 14px 10px 10px;
    position: absolute;
    text-align: left;
    top: 0;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    z-index: 99999;
}
.sidebar-mega-menu nav > ul > li:hover > ul,
.sidebar-mega-menu nav > ul > li:hover .mega-menu {
    opacity: 1;
    visibility: visible;
    left: 100%;
}
.mobile-device {
    display: none;
}
/*-----------------------------
  Toggle menu
--------------------------------*/

.body__overlay {
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s, transform 0.3s ease 0s;
    visibility: hidden;
    width: 100%;
    z-index: 99999;
}
.body__overlay.is-visible {
    opacity: 1;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0s, transform 0.3s ease 0s;
    visibility: visible;
}
.offsetmenu {
    background: #000 none repeat scroll 0 0;
    display: block;
    height: 100vh;
    opacity: 0;
    position: fixed;
    right: -100%;
    top: 0;
    transition: all 0.25s ease 0s;
    width: 450px;
    z-index: 99999;
}
.offsetmenu.offsetmenu__on {
    opacity: 1;
    padding-bottom: 50px;
    right: calc(0px - 16px);
}
.offsetmenu__inner {
    height: 100%;
    padding: 60px 50px 100px;
}
.offsetmenu__close__btn {
    padding: 29px 50px 24px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}
.offsetmenu__close__btn a i {
    color: #fff;
    font-size: 25px;
    transition: all 0.3s ease 0s;
}
.offsetmenu__close__btn a:hover i {
    color: #ff4136;
}
.off__contact p {
    color: #d1d1d1;
    font-size: 13px;
    line-height: 22px;
    margin-top: 25px;
}
.offset__widget h4 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 33px;
    text-transform: capitalize;
}
.offset__widget {
    margin-top: 67px;
}
.single__off__address span {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}
.single__off__address p {
    color: #d8d8d8;
    font-size: 13px;
}
.single__off__address {
    margin-top: 12px;
}
.off__contact__address {
    margin-top: -12px;
}
.offset__subs h4 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 32px;
    text-transform: capitalize;
}
.offset__subs {
    margin-top: 72px;
}
.offset__input input {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: transparent none repeat scroll 0 0;
    border-color: currentcolor currentcolor #fff;
    border-image: none;
    border-style: none none solid;
    border-width: 0 0 1px;
    color: #fff;
    font-size: 12px;
    padding-bottom: 11px;
    padding-right: 17px;
}
.offset__input input::-moz-placeholder {
    color: #fff;
    opacity: 1;
}
.offset__input input::-webkit-placeholder {
    color: #fff;
    opacity: 1;
}
.offset__input {
    position: relative;
}
.offset__input a {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.offset__input a i {
    color: #ffffff;
    font-size: 14px;
}
.off__soaial__link {
    display: flex;
}
.off__soaial__link li + li {
    margin-left: 26px;
}
.off__soaial__link li a i {
    color: #ffffff;
    font-size: 15px;
    transition: all 0.3s ease 0s;
}
.off__soaial__link li a:hover i {
    color: #6cc47f;
}
.offset__sosial__share {
    margin-top: 23px;
}
.offsetmenu.offsetmenuleft {
    background: #000 none repeat scroll 0 0;
    display: block;
    height: 100vh;
    opacity: 0;
    position: fixed;
    left: -100%;
    top: 0;
    transition: all 0.25s ease 0s;
    width: 450px;
    z-index: 99999;
}
.offsetmenu.offsetmenuleft.offsetmenu__on {
    opacity: 1;
    padding-bottom: 50px;
    left: calc(0px - 16px);
}
/* main menu home-2 */

.sidebar-menu-style {
    background: #222 none repeat scroll 0 0;
    display: block;
    float: left;
    height: 818px;
    padding: 52px 10px 48px;
    position: fixed;
    top: 0;
    transition: all 0.4s ease 0s;
    width: 170px;
    z-index: 99999;
}
.wrapper {
    margin-left: 200px;
    margin-right: 46px;
}
.sidebar-menu > a {
    color: #ffffff;
    display: inline-flex;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transform: rotate(-90deg);
}
.sidebar-menu > a i {
    padding-right: 6px;
}
.sidebar-social ul li {
    display: inline-block;
    margin: 0 13px;
}
.sidebar-social ul li a {
    color: #fff;
    font-size: 16px;
}
.sidebar-social ul li a:hover {
    color: #fea1ac;
}
.sidebar-menu {
    padding: 272px 0 335px;
}
/* main menu home-3 */

.absolute-bar {
    position: absolute;
    transition: all 0.5s ease 0s;
    width: 100%;
    z-index: 99999;
}
/*.header-style-3 .main-menu ul,
.header-style-4 .main-menu ul {
    padding-top: 16px;
}*/
.header-style-3 .header-search-cart,
.header-style-3 .header-search-cart {
    padding-top: 17px;
}
.header-style-4 .header-search-cart {
    padding-top: 17px;
}
.header-style-3 .header-top {
    padding: 40px 90px;
    transition: all 0.3s ease 0s;
}
.header-style-4 .header-top {
    padding: 10px 75px;
}
.header-style-5 .header-top {
    padding: 17px 75px;
}
/*.header-style-5 .main-menu ul {
    padding-top: 18px;
}*/
.header-style-5 .header-search-cart {
    padding-top: 17px;
}
/* main menu home-6 */

.home-six-slider-1,
.home-six-slider-2,
.home-six-slider-3 {
    position: relative;
}
.header-style-6 .header-top {
    padding: 20px 75px;
    transition: all .3s ease 0s;
}
.height-100-vh {
    height: 100vh;
}
/* shop 2 menu */

/*.header-bottom-shop .main-menu nav > ul > li > a {
    color: #fff;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 0.4px;
    padding: 29px 0 27px;
    text-transform: capitalize;
}*/
.header-top-shop .header-search-cart {
    padding-top: 17px;
}
.slider-content-shop {
    padding-left: 198px;
}
/*--------- 3. Slider style --------*/

.slider-content h2 {
    color: #fff;
    font-size: 120px;
    margin: 0;
    font-family: 'Artely Inks PERSONAL USE';
}
.slider-content h2 span.brand-text {
    color: #fea1ac;
}
.slider-content h2 span.black-text {
    color: #000;
}
.slider-content.text-center > h3 {
    color: #ffffff;
    font-size: 48px;
    margin: 11px 0 38px;
}
.slider-content > a {
    background-color: #333333;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 20px 40px 17px;
}
.slider-content > a:hover {
    background-color: #fea1ac;
}
.slider-active.owl-carousel .owl-nav div {
    background: #fea1ac none repeat scroll 0 0;
    border-radius: 50px;
    color: #fff;
    font-size: 18px;
    height: 35px;
    left: 375px;
    line-height: 38px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 76px;
}
.slider-active.owl-carousel .owl-nav div:hover {
    background: #000 none repeat scroll 0 0;
}
.slider-active.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: 375px;
}
.slider-area:hover .slider-active.owl-carousel .owl-nav div {
    opacity: 1;
    visibility: visible;
}
/* slider 2 */

.sidebar-bnner {
    position: relative;
}
.sidebar-bnner > img {
    width: 100%;
}
.sidebar-bnner-text {
    left: 0;
    padding-left: 154px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.sidebar-bnner-text h2 {
    color: #ffffff;
    font-size: 150px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.sidebar-bnner img {
  width: 100%;
}
/* slider 3 */

.slider-content-3 > h3 {
    color: #000000;
    font-family: "Artely Inks PERSONAL USE";
    font-size: 48px;
    letter-spacing: 1.4px;
    line-height: 1;
    margin-bottom: 4px;
}
.slider-content-3 h2 {
    color: #000000;
    font-size: 72px;
    font-weight: bold;
    letter-spacing: 11px;
    text-transform: uppercase;
    margin: 0;
}
.slider-area-three {
    padding: 244px 0 301px;
}
/* slider 4 */

.slider-4-style {
    background-color: #fff;
    display: inline-block;
    height: 460px;
    width: 478px;
    position: relative;
}
.slider-4-position {
    left: 0;
    overflow: hidden;
    padding: 0 96px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}
.slider-4-position h1 {
    color: #000000;
    font-size: 120px;
    font-weight: bold;
    line-height: 103px;
    margin: 0;
    overflow: hidden;
}
.slider-4-position > h3 {
    color: #fea1ac;
    font-family: "Artely Inks PERSONAL USE";
    font-size: 120px;
    line-height: 90px;
    margin: 0;
}
.slider-active.owl-carousel.slider-4 .owl-nav div.owl-next {
    left: auto;
    right: 77px;
}
.slider-active.owl-carousel.slider-4 .owl-nav div {
    left: 77px;
}
/* slider 5 */

.owl-carousel .owl-item .slider-5-style > img {
    margin-bottom: -2px;
    width: inherit;
}
.add-text h2 {
    color: #3b3d3e;
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    font-weight: bold;
}
.add-text {
    background-color: #fff;
    display: inline-block;
    padding: 25px 335px 32px 30px;
    width: 954px;
}
.add-text > span {
    color: #666666;
    letter-spacing: .4px;
}
.slider-active-5 {
    position: relative;
}
.slider-active-5.owl-carousel .owl-nav {
    bottom: -35px;
    display: inline-block;
    left: 948px;
    position: absolute;
}
.slider-active-5.owl-carousel .owl-nav div {
    background: #7c7c7c none repeat scroll 0 0;
    color: #000;
    font-size: 24px;
    height: 70px;
    line-height: 70px;
    opacity: 1;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    width: 70px;
}
.slider-active-5.owl-carousel .owl-nav div:hover {
    background: #16a390 none repeat scroll 0 0;
}
.slider-active-5.owl-carousel .owl-nav div.owl-next {
    background: #000000 none repeat scroll 0 0;
    color: #fff;
}
.slider-active-5.owl-carousel .owl-nav div.owl-next:hover {
    background: #333 none repeat scroll 0 0;
}
/* slider 6 */

.slider-position {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.slider-banner-content h3 {
    color: #7c7c7c;
    font-size: 40px;
    letter-spacing: 0.4px;
    margin-bottom: 37px;
}
.slider-banner-content h2 {
    color: #fff;
    font-size: 100px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
}
.side-menu-icon.f-right > a {
    color: #fff;
    display: inline-block;
    font-size: 22px;
    padding-top: 10px;
}
.slider-banner-content > a {
    border: 1px solid #fff;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 1;
    padding: 17px 60px 16px;
    margin-top: 25px;
}
.slider-banner-content > a i {
    font-size: 16px;
    padding-right: 7px;
}
.slider-banner-content > a:hover {
    color: #222;
    background-color: #fff;
}
/* slider area shop */

.slider-content-shop h4 {
    color: #70a6a0;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin-bottom: 15px;
}
.slide-shop-color.slider-content-shop. h4 {
    color: #f6aeb2;
}
.slider-content-shop > h2 {
    color: #222831;
    font-size: 120px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 1;
    margin: 0;
}
.slider-content-shop > h3 {
    color: #222831;
    font-size: 72px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin: 3px 0 36px;
}
.slide-shop-color.slider-content-shop > h3 {
    color: #a8daec;
}
.slider-content-shop > a {
    background-color: #222831;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 18px 44px;
    text-transform: capitalize;
}
.slider-content-shop > a:hover {
    background-color: #7c7c7c;
}
.slider-active.owl-carousel.shop-slider-2 .owl-nav div.owl-next {
    left: auto;
    right: 36px;
}
.slider-active.owl-carousel.shop-slider-2 .owl-nav div {
    left: 36px;
    background: transparent none repeat scroll 0 0;
    border: 2px dotted #fff;
}
.slider-active.owl-carousel.shop-slider-2 .owl-nav div {
    background: transparent none repeat scroll 0 0;
    border: 0px dotted #fff;
    color: #fff;
    height: 31px;
    left: 36px;
    line-height: 30px;
    width: 62px;
}
.slider-active.owl-carousel.shop-slider-2 .owl-nav div:hover {
    background: #000000;
    border: 0px dotted #7c7c7c;
    color: #fff;
}
.slider-active.owl-carousel.shop-slider-2 .owl-nav div {
    left: 36px;
}
/* slider company */

.slider-content-company h2 {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: url("../../img/bg/1.jpg");
    color: #333333;
    font-size: 120px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 101px;
    margin: 0;
    text-transform: uppercase;
}
.slider-content-company > h3 {
    color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.4px;
    margin: 19px 0 23px;
    position: relative;
}
.slider-content-company > h3::before {
    background-color: #fff;
    bottom: 17px;
    content: "";
    height: 7px;
    position: absolute;
    right: 674px;
    width: 38px;
}
.slider-content-company > a {
    background-color: #fff;
    border-radius: 50px;
    color: #333333;
    display: inline-block;
    letter-spacing: 0.8px;
    line-height: 1;
    padding: 20px 40px 18px;
}
.slider-content-company > a:hover {
    background-color: #76c1e9;
    color: #fff;
}
.slider-active.owl-carousel.shop-slider-2.company-slider .owl-nav div:hover {
    background: #76c1e9 none repeat scroll 0 0;
    border: 2px dotted #7c7c7c;
    color: #fff;
}
/*--------- 4. Portfolio style ------------*/

.portfolio-img-text img {
    width: 100%;
}
.portfolio-menu-style > button {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 50px;
    color: #333333;
    display: inline-block;
    line-height: 1;
    padding: 12px 30px 9px;
    text-transform: capitalize;
}
.portfolio-menu-style > button.active {
    border: 1px solid #ebebeb;
    color: #fea1ac;
}
.portfolio-menu-style > button:hover {
    color: #fea1ac;
}
.single-portfolio {
    position: relative;
}
.portfolio-title {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.4s ease-in-out 0s;
    z-index: 999;
    padding: 20px;
}
.single-portfolio:hover .portfolio-title {
    opacity: 1;
}
.portfolio-hover::before {
    background-color: #fff;
    bottom: 0px;
    content: "";
    left: 0px;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 99;
}
.portfolio-hover:hover::before {
    opacity: 0.8;
}
.portfolio-title > h3 {
    color: #222222;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-bottom: 40px;
    position: relative;
    text-transform: capitalize;
    transform: translateY(-10px);
    transition: all 0.4s ease-in-out 0s;
}
.portfolio-title > h3::before {
    background-color: #000000;
    bottom: -21px;
    content: "";
    height: 3px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 40px;
}
.portfolio-title > h3 a {
    color: #222222;
}
.portfolio-title > h3 a:hover {
    color: #fea1ac;
}
.portfolio-title > a {
    color: #222222;
    display: inline-block;
    font-size: 48px;
    transform: translateY(10px);
    transition: all 0.4s ease-in-out 0s;
}
.portfolio-title > a:hover {
    color: #fea1ac;
}
.single-portfolio:hover .portfolio-title > h3,
.single-portfolio:hover .portfolio-title > a {
    transform: translateY(0px);
}
.single-portfolio {
    position: relative;
}
.portfolio-hover::after {
    border: 2px solid #000;
    bottom: 20px;
    content: "";
    left: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    border-radius: 5px;
    z-index: 99;
}
.portfolio-hover:hover::after {
    opacity: 1;
}
.load-more > a {
    border: 1px solid #e1e1e1;
    border-radius: 50px;
    color: #666666;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    padding: 17px 40px 16px;
    text-transform: capitalize;
}
.load-more > a i {
    color: #2f2f2f;
    padding-right: 8px;
    transition: all 0.3s ease 0s;
}
.load-more > a:hover i {
    color: #fff;
}
.load-more > a:hover {
    border: 1px solid #fea1ac;
    color: #fff;
    background-color: #fea1ac;
}
/* portfolio 2 */

.col-lg-2.portfolio-width {
    width: 20%;
}
/* portfolio 3 */

.portfolio-area {
    overflow: hidden;
}
.portfolio-area-three {
    padding: 100px 75px;
}
/* portfolio 4 */

.portfolio-area-four .row {
    margin: 0 -35px;
}
.portfolio-area-four .grid .col-md-6 {
    padding: 0 35px;
}
.portfolio-hover-4 {
    position: relative;
}
.portfolio-hover-4::before {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.4s ease-in-out 0s;
}
.portfolio-hover-4:hover::before {
    opacity: 0.45;
}
.portfolio-hover-4 .portfolio-title > h3 {
    color: #fff;
}
.portfolio-hover-4 .portfolio-title > h3 a {
    color: #fff;
}
.portfolio-hover-4 .portfolio-title > h3 a:hover {
    color: #fea1ac;
}
.portfolio-hover-4 .portfolio-title > a {
    color: #fff;
}
.portfolio-hover-4 .portfolio-title > a:hover {
    color: #fea1ac;
}
.portfolio-hover-4 .portfolio-title > h3::before {
    background-color: #fff;
}
.pro-cart-bottom {
    text-align: right;
}
.pro-cart-title > h3 {
    color: #000000;
    font-size: 36px;
    font-weight: normal;
    letter-spacing: 0.4px;
    margin-bottom: 6px;
    text-transform: capitalize;
}
.pro-cart-title > h3 a:hover {
    color: #fea1ac;
}
.pro-cart-title > span {
    color: #727272;
    font-weight: 300;
    text-transform: capitalize;
}
.pro-cart-title.pro-cart-bottom {
    display: block;
    margin-top: 91px;
}
.pro-cart-title.pro-cart-top {
    margin-bottom: 88px;
    display: block;
}
.portfolio-area-four {
    padding: 90px 75px 90px;
}
.testimonials-area-four {
    padding-left: 75px;
    padding-right: 75px;
}
/*-------- 5. Brand style -----*/

.brand-logo-active.owl-carousel .owl-nav div {
    color: #bcbcbc;
    font-size: 14px;
    left: -51px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
}
.brand-logo-active.owl-carousel .owl-nav div:hover {
    color: #fea1ac;
}
.brand-logo-active.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: -51px;
}
.brand-hover:hover .brand-logo-active.owl-carousel .owl-nav div {
    opacity: 1;
    visibility: visible;
}
.brand-hover {
    padding: 0 51px;
}
.brand-hover.brand-hover-2 {
    border: 1px solid #ebebeb;
    padding: 35px 74px;
}
/*-------- 6. Testimonials style ------*/

.testimonials-img {
    display: inline-block;
    float: left;
    position: relative;
}
.testimonials-text {
    padding-left: 100px;
}
.testimonials-img > span {
    background-color: #fff;
    border-radius: 50px;
    color: #222222;
    display: inline-block;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 4px;
    text-align: center;
    top: -1px;
    width: 20px;
}
.testimonials-text h4 {
    color: #222222;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}
.testimonials-text p {
    color: #666666;
    letter-spacing: 0.4px;
    margin-bottom: 16px;
}
.testimonials-active-4 .testimonials-text p {
    padding-right: 80px;
}
.border-top {
    border-top: 1px solid #f6f6f6;
}
/* testimonials three area */

.testimonials-area-three {
    overflow: hidden;
}
.testimonials-area-three {
    padding: 53px 75px 0;
}
.testimonials-title > h4 {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 7px;
    text-transform: uppercase;
}
.testimonials-title > h2 {
    color: #000000;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin: 0;
    text-transform: uppercase;
}

.testimonials-title {
    padding-right: 98px;
    padding-top: 120px;
}
.testimonials-img-3 {
    display: inline-block;
    float: left;
}
.testimonials-text-3 i {
    color: #222222;
    font-size: 24px;
}
.testimonials-text-3 > p {
    color: #222222;
    display: block;
    letter-spacing: 0.4px;
    margin: 22px 0 48px;
    position: relative;
}
.testimonials-text-3 > h4 {
    color: #222222;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}
.testimonials-text-3 > p::before {
    background-color: #000000;
    bottom: -20px;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    width: 40px;
}
.testimonials-text-3 {
    padding-left: 278px;
    padding-top: 67px;
}
.testimonials-img-3 > img {
    width: 100%;
}
/*------- 7. Footer style --------*/

.footer-menu ul li {
    display: inline-block;
    margin: 0 13px;
}
.footer-menu ul li a {
    color: #fefefe;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
.footer-menu ul li a:hover,
.footer-social.common-footer li a:hover {
    color: #fea1ac;
}
.copyright > p {
    color: #fff;
    text-transform: capitalize;
}
.copyright > p a {
    color: #fea1ac;
}
.copyright > p a:hover {
    text-decoration: underline;
}
.common-footer {
    display: inline-block;
}
.footer-icon-img {
    margin: 0 47px 0 60px;
}
.footer-social.common-footer li {
    display: inline-block;
    margin: 0 13px;
}
.footer-social.common-footer li a {
    color: #fff;
}
.footer-menu {
    padding: 20px 0 0;
}
/* footer 5 */

.footer-style-2 {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
}
.footer-style-2-left p,
.footer-style-2-left p a {
    color: #fff;
}
.footer-style-2-left p a {
    color: #7c7c7c;
}
.footer-style-2-left p a:hover {
    text-decoration: underline;
}
.footer-menu-2 li {
    display: inline-block;
    margin: 0 13px;
}
.footer-menu-2 li a {
    color: #fff;
}
.footer-menu-2 li a {
    color: #fefefe;
    font-size: 12px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
.footer-menu-2 li a:hover,
.footer-social-2 li a:hover {
    color: #7c7c7c;
}
.footer-menu-2,
.footer-social-2 {
    padding-top: 3px;
}
.footer-social-2 li {
    display: inline-block;
    margin-left: 21px;
}
.footer-social-2 li:first-child {
    margin-left: 0px;
}
.footer-social-2 li a {
    color: #ffffff;
}
/* footer 6 */

.single-slider {
    position: relative;
}
.home-six-wrapper {
    position: relative;
}
.home-six-wrapper .slider-position {
    padding: 10px 0;
}
.footer-position {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
}
.footer-style-6 {
    padding: 0 75px;
}
.footer-social-6.f-right ul li {
    display: inline-block;
    margin-left: 21px;
}
.footer-social-6.f-right ul li a {
    color: #ddd;
}
.home-six-slider-3 .footer-social-6.f-right ul li a {
    color: #000000;
}
.footer-social-6.f-right ul li a:hover {
    color: #7c7c7c;
}
.footer-copyright-6 p {
    color: #ddd;
    letter-spacing: 0.4px;
}
.home-six-slider-3 .footer-copyright-6 p {
    color: #000000;
}
.footer-copyright-6 p a {
    color: #7c7c7c;
}
.home-six-slider-3 .footer-copyright-6 p a {
    color: #7c7c7c;
}
.footer-copyright-6 p a:hover {
    text-decoration: underline;
}
.footer-social-6 ul {
    padding-top: 4px;
}
/* shop footer */

.footer-shop-content {
    display: flex;
    justify-content: space-between;
    padding: 0 75px;
}
.shop-copyright-menu {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}
.shop-copyright p {
    color: #ffffff;
}
.shop-copyright p a,
.shop-menu-footer ul li a:hover,
.shop-footer-social ul li a:hover {
    color: #7c7c7c;
}
.shop-copyright p a:hover {
    text-decoration: underline;
}
.shop-menu-footer li {
    display: inline-block;
    margin-right: 25px;
}
.shop-menu-footer li:last-child {
    margin-right: 0px;
}
.shop-menu-footer ul li a {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .4px;
    font-size: 12px;
}
.shop-img-footer > img {
    margin: 0 108px 0 95px;
}
.shop-footer-social ul li {
    display: inline-block;
    margin-left: 26px;
}
.shop-footer-social ul li a {
    color: #fff;
}
.shop-footer-social {
    padding-top: 17px;
}
/* footer shop 2 */

.footer-shop-2 .copyright > p a {
    color: #7c7c7c;
}
.footer-shop-2 .footer-social.common-footer ul li a:hover,
.footer-shop-2 .footer-menu ul li a:hover {
    color: #7c7c7c;
}
.language-currency li {
    display: inline-block;
    margin-right: 13px;
}
.language-currency li span {
    color: #e1e1e1;
}
.language-currency li a {
    color: #222831;
    letter-spacing: 0.6px;
    margin-right: 13px;
    text-transform: uppercase;
}
.language-currency li a:hover {
    color: #7c7c7c;
}
.footer-bottom {
    overflow: hidden;
    padding: 33px 0;
}
.language-currency {
    padding-top: 4px;
}

/*-------- 8. Shop style --------*/

.product-img img {
    width: 100%;
}
.product-area-all .col-lg-2 {
    width: 20%;
}
.product-img {
    position: relative;
}
.product-img > span {
    font-size: 18px;
    font-weight: 600;
    left: 30px;
    letter-spacing: 1px;
    position: absolute;
    top: 25px;
    text-transform: uppercase;
    color: #000000;
}
.product-img > span.pro-brand-color {
    color: #fea1ac;
}
.product-title > h3 {
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 13px;
}
.product-title > h3 a {
    color: #000000;
}
.product-title > h3 a:hover {
    color: #fea1ac;
}
.product-title > span {
    color: #878787;
}
.product-title {
    padding: 27px 10px;
}
.product-img {
    position: relative;
}
.product-img::before {
    background-color: #f2f3f5;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease 0s;
}
.single-product:hover .product-img::before, .single-product.cancelled .product-img::before, .single-list-img.cancelled .product-img::before {
    opacity: .6;
}
.section-title > h3 {
    color: #686868;
    font-size: 28px;
    letter-spacing: 1.6px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
}
.section-title > h3 span {
    color: #fea1ac;
}
/* section-title-2 */

.section-title-2 > h3 {
    color: #222831;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.6px;
    margin: 0;
    text-transform: uppercase;
}
.section-title-2 > h3 span {
    color: #686868;
}
/* shop 1 */

.shop-wrapper {
    overflow: hidden;
    padding: 0 75px;
}
.shop-action {
    display: flex;
    justify-content: space-between;
}
.portfolio-shop a > img {
    width: 100%;
}
.portfolio-shop a.mrg-top > img {
    margin-top: 2px;
}
.portfolio-shop {
    position: relative;
    overflow: hidden;
}
.shop-action {
    background-color: rgba(255, 255, 255, 0.75);
    bottom: -50px;
    left: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: all .3s ease 0s;
}
.portfolio-shop:hover .shop-action {
    bottom: 0px;
    opacity: 1;
}
.shop-action-title.text-center > h3 {
    color: #222831;
    font-size: 19px;
    letter-spacing: 0.4px;
    margin-bottom: 14px;
}
.shop-action-title > h3 a {
    color: #222831;
}
.shop-action-title > h3 a:hover {
    color: #7c7c7c;
}
.shop-action-title > span {
    color: #878787;
    font-size: 15px;
}
.shop-action-cart {
  border-right: 1px solid #ebebeb;
  padding: 37px 10px 34px;
  text-align: center;
  width: 10%;
    position: relative;
}
.shop-action-love {
  border-left: 1px solid #ebebeb;
  padding: 37px 10px 34px;
  text-align: center;
  width: 10%;
    position: relative;
}
.shop-action-cart > a, .shop-action-love a {
  color: #222831;
  display: block;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) ;
    position: absolute;
}
.shop-action-cart > a:hover,
.shop-action-love a:hover {
    color: #7c7c7c;
}
.shop-wrapper .header-bar.sidebar-menu-active > a {
    color: #222831;
    display: inline-block;
    font-size: 20px;
    margin-top: 4px;
}
.shop-wrapper .header-bar.sidebar-menu-active > a:hover {
    color: #7c7c7c;
}

.shop-action-title {
  width: 80%;
    padding: 37px 10px 34px;
}
.portfolio-menu-style-2 > button {
    background-color: transparent;
    border: medium none;
    color: #000000;
    letter-spacing: 0.4px;
    margin: 0 18px;
    padding: 0;
    text-transform: capitalize;
    transition: all .3s ease 0s;
}
.portfolio-menu-style-2 > button.active,
.portfolio-menu-style-2 > button:hover {
    color: #7c7c7c;
}
.portfolio-logo {
    margin-bottom: 53px;
}
.diff-color .header-cart a span,
.diff-color .common-style:hover > a {
    color: #7c7c7c;
}
.diff-color .checkout a:hover {
    background-color: #7c7c7c;
    border: 1px solid #7c7c7c;
}
.diff-color .cart-del:hover {
    color: #7c7c7c;
}
.single-banner {
    position: relative;
}
.banner-text {
  left: 0;
  padding: 0 50px;
  position: absolute;
  top: 190px;
}
.banner-text h2 {
    color: #222831;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 42px;
    margin-bottom: 2px;
    text-transform: uppercase;
}
.banner-text > h3 {
    color: #7c7c7c;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 26px;
    margin: 0;
    text-transform: uppercase;
}
.single-banner > img {
    width: 100%;
}
/*------ 9. Banner style -------*/

.single-banner-2 {
    position: relative;
}
.banner-text-2 {
  left: 0;
  padding: 0 88px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.banner-text-2 > h2 {
    color: #222831;
    font-size: 72px;
    letter-spacing: 4px;
    line-height: 86px;
    margin: 0;
}
.single-banner-2 > img {
    width: 100%;
}
.product-icon {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all .3s ease 0s;
}
.product-icon > a i {
    background-color: #fff;
    border-radius: 50px;
    color: #222831;
    display: inline-block;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    margin: 0 5px;
    width: 50px;
    transition: all .3s ease 0s;
}
.product-icon > a i:hover {
    background-color: #7c7c7c;
    color: #fff;
}
.product-area-2-all .single-product:hover .product-img::before {
    opacity: 0.4;
}
.product-area-2-all .single-product:hover .product-icon {
    opacity: 1;
}
.product-img > span.brand-color2 {
    color: #7c7c7c;
}
.product-area-2-all .product-title > h3 a:hover {
    color: #7c7c7c;
}
.about-shop-content > p {
    letter-spacing: 0.4px;
    line-height: 29px;
    margin: 0 auto;
    width: 53%;
}
.about-shop-content > a {
    color: #000000;
}
.about-shop-content > a i {
    color: #222831;
    font-size: 18px;
    margin-right: 10px;
}
.about-shop-content > a {
    color: #000000;
    display: inline-block;
    margin-top: 105px;
    position: relative;
}
.about-shop-content > a:hover {
    color: #7c7c7c;
}
.about-shop-content > a::before {
    background-color: #dcdee0;
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -46px;
    width: 107px;
}
.single-service i {
    background-color: #fff;
    border-radius: 50px;
    color: #222831;
    display: inline-block;
    font-size: 24px;
    height: 87px;
    line-height: 87px;
    text-align: center;
    width: 87px;
    transition: all .3s ease 0s;
}
.single-service:hover i {
    color: #7c7c7c;
}
.single-service > h3 {
    color: #222831;
    font-size: 18px;
    margin: 48px 0 32px;
    text-transform: capitalize;
}
.single-service > p {
    letter-spacing: 0.4px;
    line-height: 29px;
}
.single-service {
    padding: 0 69px;
}
.services-shop {
    padding-top: 90px;
}
/*------ 10. Company page --------*/

.company-header .header-top {
    padding: 26px 75px;
}
/*.company-header .main-menu ul {
    padding-top: 16px;
}*/
.company-header .header-search-cart {
    padding-top: 17px;
}
h2.company-about-dec-title,
h2.company-about-dec-title-2 {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: url("../../img/bg/2.jpg");
    color: #000000;
    font-size: 80px;
    font-weight: bold;
    line-height: 97px;
    margin: 0;
    text-transform: capitalize;
    position: relative;
}
h2.company-about-dec-title::before {
    background-color: #222831;
    content: "";
    height: 15px;
    left: 270px;
    position: absolute;
    top: 57px;
    width: 78px;
}
h2.company-about-dec-title-2::before {
    bottom: 24px;
    left: 367px;
    background-color: #222831;
    content: "";
    height: 15px;
    position: absolute;
    width: 78px;
}
.company-about-dec > h4 {
    color: #76c1e9;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 26px;
    margin: 17px 0;
}
.company-about-dec > h4 span {
    color: #333333;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    text-transform: uppercase;
}
.company-about-dec > p {
    letter-spacing: 0.4px;
    line-height: 40px;
    margin-bottom: 43px;
}
.company-about-dec > a {
    background-color: #333333;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 19px 41px 17px;
    letter-spacing: .8px;
    text-transform: capitalize;
}
.company-service-icon {
    display: inline-block;
    float: left;
    margin-top: 40px;
}
.company-service-icon i {
    color: #222831;
    font-size: 48px;
    transition: all .3s ease 0s;
}
.company-single-service:hover i {
    color: #76c1e9;
}
.company-about-dec {
    padding-right: 34px;
}
.company-service-text {
    padding-left: 106px;
}
.company-service-text > h3 {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 21px;
    text-transform: capitalize;
}
.company-service-text > p {
    letter-spacing: 0.4px;
    line-height: 29px;
}
.company-single-service {
    padding-left: 40px;
    padding-right: 42px;
}
.company-single-service {
    margin-bottom: 60px;
}
.company-single-service:last-child {
    margin-bottom: 0px;
}
.company-services {
    margin-top: 111px;
}
.single-skill .skill-title {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-bottom: 17px;
    text-transform: uppercase;
}
.single-skill .progress {
    background-color: #76c1e9;
    border-radius: 0;
    box-shadow: none;
    height: 5px;
    margin-bottom: 59px;
    overflow: visible;
    position: relative;
}
.single-skill .progress-bar {
    background-color: #333333;
    box-shadow: none;
    position: relative;
}
.single-skill .progress-bar > span {
    color: #76c1e9;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    position: absolute;
    right: 0;
    top: -35px;
}
.skill-area.company-skill {
    padding-top: 184px;
}
.skill-img > img {
    width: 100%;
}
.skill-img {
    padding-left: 64px;
    margin-top: 35px;
}
.what-title > h2 {
    text-align: right;
}
.what-text > p {
    color: #fff;
    font-size: 27px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin-top: 23px;
    line-height: 40px;
}
.what-title > h2 {
    color: #222831;
    font-size: 80px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 95px;
    margin: 0;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: url("../../img/bg/4.jpg");
    position: relative;
}
.what-text {
    margin-top: 18px;
}
.what-area {
    padding: 99px 0 66px;
}
.what-title > h2::before {
    background-color: #222831;
    bottom: 24px;
    content: "";
    height: 15px;
    position: absolute;
    right: 205px;
    width: 78px;
}
.single-service-2 i {
    color: #333333;
    font-size: 48px;
    transition: all .3s ease 0s;
}
.single-service-2 h4 {
    color: #333333;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin: 33px 0 19px;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}
.single-service-2 > p {
    letter-spacing: 0.4px;
    line-height: 30px;
}
.single-service-2 {
    padding: 0 12px;
}
.portfolio-menu-style-3 {
    margin-top: -42px;
    position: relative;
    z-index: 99;
}
.portfolio-menu-style-3 > button {
    background-color: transparent;
    border: medium none;
    color: #333333;
    letter-spacing: 0.6px;
    margin-left: 47px;
    padding: 0;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}
.portfolio-menu-style-3 > button.active,
.portfolio-menu-style-3 > button:hover {
    color: #76c1e9;
}
.company-portfolio {
    margin-top: 57px;
}
.company-portfolio-area {
    padding: 83px 0 67px;
}
.project-content > h3 {
    color: #76c1e9;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin-bottom: 31px;
}
.project-content > h2 {
    color: #ffffff;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 60px;
    margin: 0;
}
.project-btn > a {
    background-color: #76c1e9;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    line-height: 1;
    padding: 20px 43px;
    letter-spacing: .8px;
    transition: all .3s ease 0s;
}
.project-btn > a:hover {
    background-color: #fff;
    color: #333;
}
.project-btn {
    margin-top: 85px;
}
.team-img {
    overflow: hidden;
    position: relative;
}
.team-social {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    bottom: -30px;
    left: 0;
    padding: 14px 0 14px;
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all .3s ease 0s;
}
.single-team.text-center:hover .team-social {
    bottom: 0px;
    opacity: 1;
}
.team-social ul li {
    display: inline-block;
    margin: 0 15px;
    position: relative;
}
.team-social ul li a {
    color: #333333;
    font-size: 12px;
}
.team-social ul li a:hover {
    color: #76c1e9;
}
.team-social ul li::before {
    background-color: #e1e1e1;
    content: "";
    height: 12px;
    left: 28px;
    position: absolute;
    top: 4px;
    width: 1px;
}
.team-social ul li:last-child::before {
    display: none;
}
.team-img > img {
    width: 100%;
}
.team-content > h4 {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
}
.team-content > span {
    color: #999999;
    letter-spacing: 0.4px;
}
.team-content {
    padding: 30px 10px 26px;
}
.team-section-title > h2 {
    color: #333333;
    font-size: 90px;
    font-weight: bold;
    letter-spacing: 4px;
    margin-bottom: 55px;
    position: relative;
}
.team-section-title > h2 span {
    background-clip: text;
    background-image: url("../../img/bg/6.jpg");
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
.team-section-title > h2::before {
    background-color: #222831;
    bottom: 24px;
    content: "";
    height: 15px;
    position: absolute;
    right: 246px;
    width: 70px;
}
.count-down-title > h2 {
    color: #333333;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 57px;
    text-align: right;
}
.single-count i {
    color: #999999;
    font-size: 30px;
}
.single-count > h3 {
    color: #333333;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.8px;
    margin: 9px 0;
}
.single-count > span {
    color: #999999;
    letter-spacing: 0.4px;
}
.single-count {
    background-color: #f2f3f6;
    border-radius: 5px;
    padding: 35px 12px 37px 30px;
}
.count-down-title {
    margin: 22px 62px 50px 0;
}
.textimonials-company {
    padding: 113px 87px;
}
.sin-testiText > p {
    border: 1px solid #555555;
    color: #ffffff;
    letter-spacing: 0.4px;
    line-height: 27px;
    margin: 0;
    padding: 40px;
}
.slider-title > h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0;
}
.slider-title > h5 {
    color: #fff;
    font-family: "Artely Inks PERSONAL USE";
    margin: 13px 0 15px;
}
.slider-title > span {
    margin: 0;
}
.slider-title > span i {
    color: #76c1e9;
    font-size: 12px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    border-radius: 50px;
    background-color: #fff;
}
.sin-testiImage.slick-slide.slick-current.slick-active.slick-center {
    margin-top: 0;
}
.sin-testiImage img {
    background-color: #f3f3f3;
    border-radius: 100%;
    display: inline-block !important;
    height: 50px;
    opacity: 0.7;
    position: relative;
    width: 50px;
}
.slider-title {
    margin-top: 48px;
}
.sin-testiImage.slick-center img {
    height: 70px;
    width: 70px;
    opacity: 1;
}
.testimonial-image-slider {
    margin-top: 9px;
}
.testimonial-image-slider .slick-list {
    margin: 0 80px;
}
.footer-company .shop-copyright p a,
.footer-company .shop-menu-footer ul li a:hover,
.footer-company .shop-footer-social ul li a:hover {
    color: #76c1e9;
}
/* single portfolio 1 */

.green-color .header-top {
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 75px;
}
/*.green-color .main-menu ul {
    padding-top: 16px;
}*/
.green-color .header-search-cart {
    padding-top: 17px;
}
/*.green-color .main-menu nav > ul > li:hover > a,*/
.green-color .common-style:hover > a,
.header-cart a span.shop-count,
.breadcrumb-text ul li a:hover {
    color: #7c7c7c;
}
.header-cart a span.shop-count.pink {
    color: #fea1ac;
}
.breadcrumb-area {
    padding: 44px 0 45px;
}
.breadcrumb-text ul li {
    color: #000000;
    display: inline-block;
    letter-spacing: 0.4px;
    margin-right: 21px;
    text-transform: capitalize;
}
.breadcrumb-text ul li a {
    color: #000000;
    margin-right: 20px;
}
.single-portfolio-slider-active.owl-carousel .owl-nav div {
    border-radius: 50px;
    font-size: 18px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    background: transparent none repeat scroll 0 0;
    border: 2px dotted #fff;
    color: #fff;
    height: 31px;
    left: 40px;
    line-height: 30px;
    width: 62px;
}
.single-portfolio-slider-active.owl-carousel .owl-nav div:hover {
    background: #7c7c7c;
    border: 2px dotted #7c7c7c;
}
.single-portfolio-slider-active.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: 40px;
}
.single-portfolio-slider-area:hover .single-portfolio-slider-active.owl-carousel .owl-nav div {
    opacity: 1;
    visibility: visible;
}
.project-details h2 {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 24px;
}
.project-details > p {
    letter-spacing: 0.4px;
    line-height: 26px;
    margin-bottom: 27px;
    padding-right: 13px;
}
.client-info > h3 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
}
.client-info > span {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.4px;
}
.client-info {
    margin-bottom: 29px;
}
.client-info-social li {
    display: inline-block;
    margin-right: 23px;
}
.client-info-social li a {
    color: #000000;
    font-size: 16px;
}
.client-info-social li a:hover,
.button-search2:hover {
    color: #7c7c7c;
}
.client-info-social {
    display: inline-block;
    float: left;
}
.client-info-search {
    display: inline-block;
    position: relative;
}
.button-search2 {
    background-color: transparent;
    border: medium none;
    color: #000;
    font-size: 16px;
    padding: 0;
    padding-right: 15px;
    transition: all .3s ease 0s;
}
.client-info-search:hover .button-search2 {
    color: #7c7c7c;
}
.client-search-content form input {
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    height: 38px;
    left: 25px;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: -8px;
    transition: all 0.4s ease 0s;
    width: 0;
}
.client-info-search:hover .client-search-content form input {
    opacity: 1;
    padding: 10px 16px;
    width: 200px;
}
.single-portfolio-img-area > img {
    width: 100%;
}
.client-info-all .client-info > h3 {
    display: inline-block;
    float: left;
}
.client-info-title > span {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.4px;
}
.client-info-title {
    padding-left: 119px;
}
.client-info-all .client-info-social {
    padding-left: 44px;
}
.project-details.single-portfolio-3 > p {
    margin-bottom: 0px;
    padding-right: 46px;
}
.single-portfolio-img > img {
    width: 100%;
}
/* 11. About page */

.company-about-dec > a:hover {
    background-color: #76c1e9;
}
.about-area .company-about-dec > a:hover,
.about-skill-person .single-skill .progress {
    background-color: #7c7c7c;
}
.about-area .company-about-dec > h4,
.about-area .company-single-service:hover i,
.about-skill-person .single-skill .progress-bar > span,
.about-team-area .team-social ul li a:hover {
    color: #7c7c7c;
}
h2.about-section-title {
    color: #000000;
    font-size: 80px;
    font-weight: bold;
    line-height: 97px;
    margin: 0;
    position: relative;
    text-transform: capitalize;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
h2.about-section-title.about-section-title1 , h2.about-section-title.about-section-title2 {
    background-image: url(../../img/bg/2.jpg);
}

h2.about-section-title.about-section-title3 {
    background-image: url("../../img/bg/8.jpg");
}
h2.about-section-title::before {
    background-color: #222831;
    bottom: 22px;
    content: "";
    height: 15px;
    position: absolute;
    width: 78px;
}
h2.about-section-title.about-section-title1::before {
    left: 122px;
}
h2.about-section-title.about-section-title2::before {
    left: 198px;
}
h2.about-section-title.about-section-title3::before {
    left: 259px;
}
.skill-area {
    padding-top: 49px;
}
.single-skill .progress.none-mrg {
    margin-bottom: 0px;
}
.skill-img.about-skill-img {
    margin-top: 96px;
}
.company-brand-area .brand-logo-active.owl-carousel .owl-nav div:hover {
    color: #7c7c7c;
}
.computer-img.text-center > img {
    width: 100%;
}
.computer-img.text-center {
    padding: 149px 165px 0;
}
.main-services .single-service-2:hover i,
.main-services .single-service-2 h4:hover {
    color: #7c7c7c;
}
/* 12. Product page */

.product-banner-content > img {
    width: 100%;
}
.product-banner-content {
    position: relative;
}
.product-banner-text {
    left: 86px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.product-banner-text h2 {
    color: #7c7c7c;
    font-size: 36px;
    font-weight: 500;
    line-height: 45px;
    margin: 0;
    text-transform: capitalize;
}
.product-top-area {
    border-bottom: 1px solid #f7f6f8;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-bottom: 19px;
}
.sorting {
    display: inline-block;
    float: left;
}
.sorting form select {
    -moz-appearance: none;
    border: medium none;
    box-shadow: none;
    color: #000000;
    height: 37px;
    letter-spacing: 0.4px;
    margin-right: 20px;
    padding-left: 20px;
    width: 170px;
}
.sorting-bg-1 form select {
    background: #f8f8fa url("../../img/icon-img/4.png") no-repeat scroll right 20px center;
}
.sorting-bg-2 form select {
    background: #f8f8fa url("../../img/icon-img/4.png") no-repeat scroll right 64px center;
}
.product-showing > p {
    color: #000000;
    letter-spacing: 0.4px;
    padding-top: 6px;
}
ul.tab-style > li {
    display: inline-block;
    margin-left: 6px;
}
ul.tab-style > li:first-child {
    margin-left: 0px;
}
ul.tab-style > li a {
    font-size: 19px;
}
ul.tab-style > li a {
    background-color: #f7f6f8;
    border-radius: 5px;
    display: inline-block;
    font-size: 19px;
    line-height: 1;
    padding: 8px 10px 7px;
    color: #a3a2a3;
}
ul.tab-style > li.active a,
ul.tab-style > li a:hover {
    color: #000000;
}
.product-bottom-area .single-product:hover .product-icon {
    opacity: 1;
}
.product-bottom-area .product-title > h3 a:hover {
    color: #7c7c7c;
}
.product-bottom-area .product-title {
    padding: 28px 10px 15px;
}

.paginations {
    clear: both;
}

.paginations ul li {
    display: inline-block;
    margin: 0 3px;
}
.paginations ul li a {
    background-color: #f6f6f6;
    color: #000000;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    height: 45px;
    line-height: 45px;
    width: 45px;
}
.paginations ul li a i {
    font-size: 16px;
}
.paginations ul li.active a,
.paginations ul li a:hover {
    background-color: #000000;
    color: #fff;
}
.single-list-product.mb-30 {
    display: block;
    overflow: hidden;
}
.single-list-peragraph > p {
    letter-spacing: 0.4px;
}
.single-list-title > h3 {
    color: #000000;
    font-size: 22px;
    letter-spacing: 0.4px;
    margin-bottom: 13px;
}
.single-list-stock > h4 {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-top: 10px;
    text-transform: capitalize;
}
.single-list-stock > h4 span {
    color: #878787;
}
.single-list-title > h3 a {
    color: #000000;
}
.single-list-title > h3 a:hover {
    color: #7c7c7c;
}
.single-list-title > span {
    color: #878787;
    font-size: 22px;
}

.shop-list-icon > a i {
    background-color: #222831;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 50px;
}
.shop-list-icon > a i:hover {
    background-color: #7c7c7c;
}
.single-list-peragraph {
    margin: 5px 0 13px;
}
.single-list-product:hover .product-img::before {
    opacity: 0.4;
}
/* 13. Product sidebar */

.product-sidebar-single > h3 {
    border-bottom: 3px solid #ebebeb;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 24px;
}
h3.sidebar-widget {
    border-bottom: 1px solid #f4f4f4;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding-bottom: 11px;
    text-transform: uppercase;
}
.widget-categories ul li {
    margin-bottom: 16px;
}
.widget-categories ul li:last-child {
    margin-bottom: 0px;
}
.widget-categories ul li a {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
}
.widget-categories {
    margin: 18px 0 29px;
}
.price_filter .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    background: #ebebeb none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    height: 5px;
    margin-bottom: 23px;
    margin-left: auto;
}
.price_filter .ui-slider-range {
    background: #7d7d7d none repeat scroll 0 0;
}
.price_filter .ui-slider-handle.ui-state-default.ui-corner-all {
    background: #7d7d7d none repeat scroll 0 0;
    border: none;
    border-radius: 50%;
    height: 14px;
    width: 14px;
}
.label-input label {
    color: #828282;
    display: inline-block;
    float: left;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin: 0;
    text-transform: capitalize;
}
.label-input input {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    box-shadow: none;
    color: #828282;
    font-size: 14px;
    height: inherit;
    padding-left: 5px;
    width: 99px;
}
.label-input {
    display: inline-block;
    float: left;
    margin-top: 5px;
}
.price_slider_amount > button {
    background-color: #000000;
    border: medium none;
    color: #fff;
    display: inline-block;
    float: right;
    font-weight: 500;
    line-height: 1;
    padding: 9px 22px 7px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all .3s ease 0s;
}
.price_slider_amount > button:hover {
    background-color: #7c7c7c;
}
.price_slider_amount {
    display: block;
    overflow: hidden;
}
.price_filter {
    padding: 16px 0 44px;
}
.product-color > ul li {
    cursor: pointer;
    display: block;
    float: left;
    height: 34px;
    margin-right: 9px;
    text-indent: -9999px;
    transition: all 0.4s ease 0s;
    width: 37px;
}
.product-color > ul li:last-child {
    margin-right: 0px;
}
.product-color > ul li.blue {
    background: #668fbe none repeat scroll 0 0;
}
.product-color > ul li.yellow {
    background: #c2a476 none repeat scroll 0 0;
}
.product-color > ul li.gray {
    background: #f4f4f4 none repeat scroll 0 0;
}
.product-color > ul li.puce {
    background: #7e2b40 none repeat scroll 0 0;
}
.product-color > ul li.black {
    background: #362f2d none repeat scroll 0 0;
}
.product-color > ul li.pink {
    background: #e967a7 none repeat scroll 0 0;
}
.product-color {
    display: block;
    margin: 20px 0 37px;
    overflow: hidden;
}
.product-size li {
    display: inline-block;
    margin-right: 9px;
}
.product-size a {
    border: 1px solid #e1e1e1;
    display: inline-block;
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    width: 36px;
    color: #666666;
}
.product-size a:hover {
    border: 1px solid #7c7c7c;
    color: #7c7c7c;
}
.product-size {
    margin: 20px 0 37px;
}
.product-tags ul li {
    display: inline-block;
    margin: 0 5px 10px 0;
    text-transform: capitalize;
}
.product-tags ul li a {
    border: 1px solid #ebebeb;
    color: #666666;
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.4px;
    padding: 6px 9px;
}
.product-tags ul li a:hover {
    border: 1px solid #362f2d;
}
.product-tags {
    margin: 20px 0 27px;
}
.product-compare ul a {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.4px;
}
.product-compare ul a:hover,
.best-seller-text > h3 a:hover {
    color: #7c7c7c;
}
.product-compare ul a span {
    float: right;
}
.product-compare li {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 14px;
    padding-bottom: 14px;
}
.product-compare {
    margin: 20px 0 10px;
}
.compare-text h5 {
    color: #ed1c24;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin: 0;
    text-transform: uppercase;
}
.compare-text {
    display: inline-block;
    float: left;
    margin-top: 8px;
}
.compare-btn {
    display: inline-block;
    float: right;
}
.compare-btn a {
    background-color: #555555;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding: 6px 20px 4px;
    text-transform: uppercase;
}
.compare-btn a:hover {
    background-color: #7c7c7c;
}
.compare-text-btn {
    display: block;
    margin-bottom: 73px;
    overflow: hidden;
}
.best-seller-img {
    display: inline-block;
    float: left;
}
.single-best-seller {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
}
.best-seller-text > h3 {
    color: #222831;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 24px;
}
.best-seller-text > h3 a {
    color: #222831;
}
.best-seller-text > span {
    color: #878787;
    letter-spacing: 0.4px;
}
.best-seller-text {
    padding-left: 115px;
}
.single-best-seller:last-child {
    margin-bottom: 0px;
}
/* 14. product details */

.single-product-details > h2 {
    color: #222831;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin: 0;
}
.rating > span i {
    color: #636363;
    font-size: 14px;
    margin-right: 1px;
}
.rating > span span.light-color i {
    color: #c2c2c2;
}
.single-product-details > h3 {
    color: #333333;
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}
.rating {
    margin: 25px 0 23px;
}
.single-product-details > p {
    color: #888888;
    letter-spacing: 0.4px;
    line-height: 28px;
    margin: 20px 0 0px;
    padding-bottom: 13px;
}
.single-product-details {
    border-bottom: 1px solid #e1e1e1;
}
.details-stock > h4,
.details-color > h4,
.details-size > span,
.details-model > h4,
.pro-details-common > h4,
.single-product-details > h4 {
    color: #100d13;
    font-size: 14px;
    letter-spacing: 0.4px;
}
.details-stock > h4 span,
.details-model > h4 span {
    color: #868686;
}
.details-color > h4 {
    display: inline-block;
    float: left;
    margin: 2px 0 0;
}
.color-meta > ul li {
    cursor: pointer;
    display: block;
    float: left;
    height: 20px;
    margin-right: 9px;
    text-indent: -9999px;
    transition: all 0.4s ease 0s;
    width: 20px;
}
.color-meta > ul li.blue {
    background: #003471 none repeat scroll 0 0;
}
.color-meta > ul li.yellow {
    background: #754c24 none repeat scroll 0 0;
}
.color-meta > ul li.black {
    background: #100d13 none repeat scroll 0 0;
}
.details-color > a {
    border: 1px solid #d7d7d7;
    color: #5c5c5c;
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    padding: 3px 7px 1px;
    text-transform: uppercase;
}
.details-size {
    overflow: hidden;
}
.only-color-size {
    display: block;
    margin: 17px 0 16px;
    overflow: hidden;
}
.color-meta,
.details-size > span,
.details-color,
.details-size,
.details-size-select {
    display: inline-block;
    float: left;
}
.details-size-select form select {
    -moz-appearance: none;
    background: rgba(0, 0, 0, 0) url("../../img/icon-img/5.png") no-repeat scroll right 3px center;
    border: 1px solid #d7d7d7;
    color: #afafaf;
    font-size: 12px;
    height: 22px;
    padding: 0 0 0 1px;
    width: 34px;
}
.details-color {
    margin-right: 40px;
}
.color-meta {
    margin-left: 5px;
    margin-right: 4px;
}
.details-size-select {
    margin-left: 5px;
}
.details-three-btn > a {
    color: #fff;
    display: inline-block;
    letter-spacing: 0.4px;
    line-height: 1;
    padding: 11px 36px 9px;
    text-transform: uppercase;
    border: 1px solid #cccccc;
    background-color: #000000;
}
.details-three-btn > a:hover,
.details-color > a:hover {
    background-color: #666;
    color: #fff;
}
.details-compare-wishlist > a {
    border: 1px solid #cccccc;
    color: #d9d9d9;
    display: inline-block;
    line-height: 1;
    margin-right: 7px;
    padding: 11px 11px 9px;
    transition: all .3s ease 0s;
}
.details-compare-wishlist > a:hover {
    border: 1px solid #000000;
    color: #fff;
    background-color: #000000
}
.details-compare-wishlist {
    display: inline-block;
    margin-left: 7px;
}
.details-model > h4 {
    margin: 0 0 16px;
}
.pro-details-common > h4,
.single-product-details > h4 {
    display: inline-block;
    float: left;
    margin: 0 5px 0 0;
}
.pro-details-common > ul li {
    display: inline-block;
}
.pro-details-common > ul li a {
    color: #868686;
    letter-spacing: .4px;
}
.pro-details-common > ul li a:hover {
    color: #4e4e4e;
}
.pro-details-tag {
    margin-bottom: 15px;
}
.single-product-details > h4 {
    margin-top: 3px;
}
.product-social-icon ul li {
    display: inline-block;
    margin-right: 5px;
}
.product-social-icon ul li a {
    background-color: #f4f4f4;
    border-radius: 50px;
    color: #cacaca;
    display: inline-block;
    font-size: 12px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    width: 23px;
}
.product-social-icon ul li a:hover {
    color: #4e4e4e;
}
.product-details-images {
    position: relative;
}
.product-details-leftbar {
    position: relative;
}
.product-tab-details {
    bottom: 38px;
    display: block;
    position: absolute;
    text-align: center;
    width: 100%;
}
.product-tab-details a img {
    border: 3px solid #fff;
    margin: 0 7px;
}
.product-details-images img {
    width: 100%;
}
.review-shipping-topbar ul {
    border: 1px solid #e1e1e1;
    padding-left: 0;
}
.review-shipping-topbar ul > li {
    display: inline-block;
    margin-left: 0;
}
.review-shipping-topbar ul > li a {
    background-color: transparent;
    color: #888888;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1;
    padding: 14px 15px 12px;
    text-transform: uppercase;
    border-right: 1px solid #e1e1e1;
}
.review-shipping-topbar ul > li.active a {
    color: #333333;
}
.review-shipping-topbar ul > li a.shipping-none {
    border-right: 0px solid #e1e1e1;
}
.review-shipping-bottom .tab-content {
    padding-top: 27px;
}
.review-shipping-bottom #description > * {
    color: #666;
}
.review-shipping-bottom #description > ul > li {
    list-style: disc !important;
}
.review-shipping-bottom #description > ol > li {
    list-style: decimal !important;
}
.review-shipping-bottom .tab-content #description > p {
    letter-spacing: 0.4px;
    line-height: 28px;
}
.review-shipping-bottom .tab-content #description > p.description-mrg {
    margin-bottom: 6px;
}
.rattings-wrapper {
    margin-bottom: 40px;
}
.rattings-wrapper .sin-rattings {
    margin-bottom: 30px;
}
.star-author-all {
    margin-bottom: 10px;
    overflow: hidden;
}
.ratting-author > h3 {
    display: inline-block;
}
.rattings-wrapper .sin-rattings .ratting-author h3 {
    color: #606060;
    float: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    margin: 0;
    text-transform: capitalize;
}
.rattings-wrapper .sin-rattings .ratting-author span {
    color: #909090;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 13px;
    margin-left: 15px;
}
.rattings-wrapper .sin-rattings > p {
    letter-spacing: 0.4px;
    line-height: 28px;
}
.ratting-form-wrapper h3 {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    margin: 0 0 30px;
    text-transform: uppercase;
}
.ratting-form form .star-box {
    margin-bottom: 40px;
}
.ratting-form form .star-box h2 {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
    margin: 0 0 10px;
}
.rating-form-style input,
.rating-form-style textarea {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #eceff8;
    color: #333;
}
.rating-form-style textarea {
    margin-bottom: 20px;
    height: 150px;
}
.rating-form-style .submit {
    background-color: #333;
    border: medium none;
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 10px 20px 8px;
    text-transform: uppercase;
    transition: all .3s ease 0s;
}
.rating-form-style .submit:hover {
    background-color: #666;
}
.description-review-shipping {
    padding-bottom: 50px;
    padding-top: 37px;
}
.ratting-star i {
    color: #636363;
}
.related-img img {
    width: 100%;
}
.related-post-active .col-md-3 {
    width: 100%;
}
.related-post-title > h2 {
    border-bottom: 1px solid #d6d6d6;
    color: #404040;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 46px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.related-post-active.owl-carousel .owl-nav div {
    color: #c5c5c5;
    font-size: 30px;
    position: absolute;
    right: 25px;
    text-align: center;
    top: -94px;
    transition: all 0.3s ease 0s;
}
.related-post-active.owl-carousel .owl-nav div:hover {
    color: #111111;
}
.related-post-active.owl-carousel .owl-nav div.owl-next {
    right: 0;
}
.related-post-area {
    padding-bottom: 45px;
}
.related-post-area .product-title > h3 a:hover {
    color: #7c7c7c;
}
/*----------------------------*/
/*    15. Shopping cart
/*----------------------------*/

.shopping-cart-area {
    border-bottom: 1px solid #ddd;
}
.shopping-cart-area .breadcrumb {
    padding: 30px 0;
}
.shopping-cart-area .breadcrumb li a {
    color: #bdbdbd;
    transition: all 0.3s ease 0s;
}
h1.cart-heading {
    color: #252525;
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
}
.cart-title-area {
    padding-top: 30px;
}
.car-header-title h2 {
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
}
.table-content table {
    width: 100%;
}
.wishlist .table-content table {
    margin: 0 0 0px;
}
.table-content.wish table {
    margin: 0 0 0px;
}
.table-content table thead tr {
    border-bottom: 3px solid #e1e1e1;
}
.table-content table th {
    border-top: medium none;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding-bottom: 10px;
}
.table-content table td {
    padding-top: 30px;
}
.table-content table td input {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: #f7f7f7 none repeat scroll 0 0;
    border-color: currentcolor #ebebeb currentcolor currentcolor;
    border-image: none;
    border-radius: 0px;
    border-style: none solid none none;
    border-width: medium 1px medium medium;
    color: #000000;
    font-size: 14px;
    font-weight: normal;
    height: 63px;
    padding: 0;
    text-align: center;
    width: 69px;
}
.pro-details-quantity .pro-qty .qtybtn.inc {
    background-color: #f7f7f7;
    cursor: pointer;
    display: block;
    left: 70px;
    padding: 14px 0 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 55px;
    z-index: 9999;
}
.pro-details-quantity .pro-qty .qtybtn i {
    color: #000000;
    font-size: 12px;
}
.pro-details-quantity .pro-qty .qtybtn.dec {
    background-color: #f7f7f7;
    bottom: 0;
    cursor: pointer;
    display: block;
    left: 70px;
    padding: 0 0 10px;
    position: absolute;
    text-align: center;
    width: 55px;
    z-index: 9999;
}
.pro-qty {
    position: relative;
}
.table-content table td.product-subtotal.product-subtotal {
    width: 120px;
}
.table-content table td.product-subtotal {
    width: 120px;
}
.table-content table td.product-name a {
    color: #222831;
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-bottom: 19px;
}
.table-content table td.product-name > span {
    color: #878787;
    font-weight: 500;
    letter-spacing: 1px;
}
.table-content table td.product-name a:hover {
    color: #555;
}
.table-content table td.product-name {
    width: 270px;
}
.table-content table td.product-thumbnail {
    width: 200px;
}
.table-content table td.product-remove i {
    color: #000;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}
.table-content table .product-price .amount,
.table-content table td.product-subtotal {
    color: #878787;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}
.table-content table td.product-remove i:hover {
    color: #999;
}
.table-content table td.product-quantity {
    width: 180px;
}
.table-content table td.product-remove {
    width: 150px;
}
.table-content table td.product-price {
    width: 130px;
}
.cart-common-btn > button,
.cart-common-btn > a {
    background-color: #ebebeb;
    color: #000000;
    display: inline-block;
    letter-spacing: 0.4px;
    padding: 22px 35px 19px;
    text-transform: uppercase;
    border-radius: 5px;
}
.cart-common-btn > button:hover,
.cart-common-btn > a:hover {
    background-color: #000000;
    color: #fff;
}
.update-cart.cart-common-btn {
    display: inline-block;
    margin-left: 22px;
}
.cart-shiping-update {
    border-bottom: 1px solid #ebebeb;
    display: block;
    margin-bottom: 69px;
    margin-top: 36px;
    overflow: hidden;
    padding-bottom: 57px;
}
.wishlist .cart-shiping-update {
    border-bottom: medium none navy;
    margin-bottom: 0;
    padding-bottom: 0;
}
.discount-code h4 {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 24px;
    text-transform: uppercase;
}
.discount-code {
    background-color: #ebebeb;
    display: block;
    padding: 55px 70px;
}
.coupon input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    height: 56px;
    padding-left: 10px;
    padding-right: 50px;
}
.coupon {
    position: relative;
}
.button-coupon {
    background-color: #000000;
    border: medium none;
    color: #fff;
    font-weight: 500;
    height: 56px;
    letter-spacing: 0.4px;
    padding: 0 28px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    transition: all .3s ease 0s;
}
.cart-total > h3 {
    background-color: #000000;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 23px 30px 22px;
    text-transform: uppercase;
    border-radius: 5px;
}
.cart-total ul {
    padding: 37px 0 35px;
}
.cart-total ul li {
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding-bottom: 22px;
    text-transform: uppercase;
}
.cart-total ul li.order-total {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 18px;
    padding-bottom: 32px;
}
.cart-total ul li span, .cart-total ul li .to-right {
    float: right;
}
.cart-total ul li span.inline {
    float: none;
}
.cart-btn > a,
.continue-sh0pping-btn > a {
    background-color: #ebebeb;
    color: #000000;
    display: block;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding: 23px 10px 22px;
    text-transform: uppercase;
    border-radius: 5px;
}
.cart-btn > a:hover,
.continue-sh0pping-btn > a:hover {
    background-color: #000000;
    color: #fff;
}
.button-coupon:hover {
    background-color: #666;
}
.product-cart-icon.product-subtotal > a {
    color: #000000;
}
.product-cart-icon.product-subtotal > a:hover,
.table-content table td.product-name a:hover {
    color: #7c7c7c;
}
/* 16. Contact us */

#hastech {
    height: 427px;
    width: 100%;
}
.contact-wrapper {
    padding: 71px 320px 0;
}
.contac-title > h3 {
    font-size: 18px;
    color: #4d4d4d;
    font-weight: 600;
    letter-spacing: .4px;
}
.single-contact-info {
    background-color: #f5f5f5;
    padding: 48px 10px 49px;
}
.single-contact-info i {
    font-size: 36px;
    color: #7c7c7c;
}
.single-contact-info ul li i {
    font-size: 18px;
    margin-right: 7px;
}
.single-contact-info ul li {
    border-bottom: 1px solid #fff;
    color: #4d4d4d;
    letter-spacing: 0.4px;
    margin-bottom: 41px;
    padding-bottom: 30px;
}
.single-contact-info ul li a {
    color: #4d4d4d;
}
.single-contact-info ul li a:hover {
    color: #7c7c7c;
}
.single-contact-info h3 {
    color: #212121;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin: 19px 0 10px;
}
.single-contact-info p {
    letter-spacing: .4px;
}
.single-contact-info ul li:last-child {
    color: #4d4d4d;
    letter-spacing: 0.4px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}
.single-contact-info.extra-contact-info.text-center {
    background-color: #f5f5f5;
    padding: 43px 0px 48px;
}
.contac-title {
    margin-bottom: 60px;
}
.contact-info {
    margin-bottom: 71px;
}
.contact-form-style {
    position: relative;
}
.contact-form-style input {
    background: #f5f5f5 none repeat scroll 0 0;
    border: none;
    color: #666666;
    height: 60px;
    padding-left: 45px;
    margin-bottom: 30px;
    letter-spacing: .4px;
    border-radius: 5px;
    position: relative;
}
.contact-form-style input::-webkit-input-placeholder,
.contact-form-style textarea::-webkit-input-placeholder {
    /* Chrome */

    color: #666;
    opacity: 1;
}
.contact-form-style input::-moz-placeholder,
.contact-form-style textarea::-moz-placeholder {
    /* Firefox 19+ */

    color: #666;
    opacity: 1;
}
.contact-form-style::before {
    color: #212121;
    font-family: FontAwesome;
    font-size: 14px;
    position: absolute;
    z-index: 9999;
    left: 24px;
    top: 20px;
}
.contact-form-style.contact-font-one::before {
    content: "\f2c0";
}
.contact-form-style.contact-font-two::before {
    content: "\f003";
}
.contact-form-style.contact-font-three::before {
    content: "\f0c5";
}
.contact-form-style.contact-font-four::before {
    content: "\f003";
}
.contact-form-style textarea {
    background: #f5f5f5 none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    color: #666666;
    height: 220px;
    letter-spacing: 0.4px;
    margin-bottom: 30px;
    padding: 22px 10px 30px 45px;
}
.contact-form-style button.submit {
    color: #212121;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    border: none;
    background-color: #72d6c9;
    padding: 16px 30px 14px;
    border-radius: 5px;
    transition: all .3s ease 0s;
}
.contact-form-style button.submit:hover {
    color: #fff;
    background-color: #212121;
}
/* 17. Checkout page */

.coupon-accordion h3 {
    background-color: #f7f6f7;
    border-top: 3px solid #464646;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    list-style: outside none none !important;
    margin: 0 0 2em !important;
    padding: 1em 2em 1em 3.5em !important;
    position: relative;
    width: auto;
}
.panel-title > a:hover,
.panel-title > a:focus {
    color: #7c7c7c;
}
.coupon-accordion h3::before {
    color: #000;
    content: "";
    display: inline-block;
    font-family: fontawesome;
    left: 1.5em;
    position: absolute;
    top: 1em;
}
.coupon-accordion span {
    cursor: pointer;
    color: #6f6f6f;
    transition: all .3s ease 0s;
}
.coupon-accordion span:hover,
p.lost-password a:hover {
    color: #7c7c7c;
}
.coupon-content {
    border: 1px solid #e5e5e5;
    display: none;
    margin-bottom: 20px;
    padding: 20px;
}
.coupon-info {} .coupon-info p.coupon-text {
    margin-bottom: 15px
}
.coupon-info p {
    margin-bottom: 0
}
.coupon-info p.form-row-first {} .coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
    display: block;
}
.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
    color: #333;
}
.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
    border: 1px solid #e5e5e5;
    height: 36px;
    margin: 0 0 14px;
    max-width: 100%;
    padding: 0 0 0 10px;
    width: 370px;
    background-color: transparent;
}
.coupon-info p.form-row-last {} .coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
    background: #7c7c7c none repeat scroll 0 0;
}
.coupon-info p.form-row input[type="checkbox"] {
    height: inherit;
    position: relative;
    top: 2px;
    width: inherit;
}
.form-row > label {
    margin-top: 7px;
}
p.lost-password {
    margin-top: 15px;
}
p.lost-password a {
    color: #6f6f6f;
}
p.checkout-coupon input[type="text"] {
    background-color: transparent;
    border: 1px solid #ddd;
    height: 36px;
    padding-left: 10px;
    width: 170px;
}
p.checkout-coupon input[type="submit"] {
    background: #333 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    color: #fff;
    height: 36px;
    margin-left: 6px;
    padding: 5px 10px;
    transition: all 0.3s ease 0s;
    width: inherit;
    text-transform: uppercase;
}
.coupon-checkout-content {
    margin-bottom: 30px;
    display: none;
}
.checkbox-form h3 {
    border-bottom: 1px solid #e5e5e5;
    color: #000;
    font-size: 25px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    width: 100%;
}
.country-select {
    margin-bottom: 30px;
    position: relative;
}
.country-select label,
.checkout-form-list label {
    color: #000;
    display: block;
    font-size: 14px;
    margin: 0 0 5px;
}
.country-select label span.required,
.checkout-form-list label span.required {
    color: #333;
}
.country-select select {
    -moz-appearance: none;
    border: 1px solid #ddd;
    height: 42px;
    padding-left: 10px;
    width: 100%;
    background-color: transparent;
}
.country-select::before {
    content: "";
    display: inline-block;
    font-family: fontawesome;
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 31px;
}
.checkout-form-list {
    margin-bottom: 30px;
}
.checkout-form-list label {
    color: #333;
}
.checkout-form-list label span.required {} .checkout-form-list input[type=text],
.checkout-form-list input[type=password],
.checkout-form-list input[type=email],
.checkout-form-list input[type=date]{
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    height: 42px;
    width: 100%;
    padding: 0 0 0 10px;
}
.checkout-form-list {} .checkout-form-list input[type="checkbox"] {
    display: inline-block;
    height: inherit;
    margin-right: 10px;
    position: relative;
    top: 2px;
    width: inherit;
}
.ship-different-title input {
    height: inherit;
    line-height: normal;
    margin: 4px 0 0;
    position: relative;
    top: 1px;
    width: 30px;
}
.create-acc label {
    color: #333;
    display: inline-block;
}
.checkout-form-list input[type=password] {} .create-account {
    display: none
}
.ship-different-title h3 label {
    display: inline-block;
    margin-right: 20px;
    font-size: 25px;
    color: #363636;
}
.order-notes textarea {
    background-color: transparent;
    border: 1px solid #ddd;
    height: 90px;
    padding: 15px;
    width: 100%;
}
#ship-box-info {
    display: none
}
.your-order {
    background: #f2f2f2 none repeat scroll 0 0;
    padding: 30px 40px 45px;
}
.your-order h3 {
    border-bottom: 1px solid #d8d8d8;
    color: #000;
    font-size: 25px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    width: 100%;
}
.your-order-table table {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    width: 100%;
}
.your-order-table table th,
.your-order-table table td {
    border-bottom: 1px solid #d8d8d8;
    border-right: medium none;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
}
.your-order-table table tr:last-child td {
    border-bottom: 0 none;
}

.your-address {
    padding-bottom: 10px !important;
}

.your-address-table table {
    width: 100%;
}

.your-address-table .update-address {
    width: 50%;
    text-align: center;
}

.update-address i {
    color: black;
    font-size: 22px;
}

.your-address-table table th,
.your-address-table table td {
    border-right: medium none;
    font-size: 14px;
    padding: 15px 0;
    text-align: left;
}

.your-order-table table th {
    border-top: medium none;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    width: 250px;
}
.your-order-table table .shipping ul li input {
    position: relative;
    top: 2px;
}
.your-order-table table .shipping th {
    vertical-align: top;
}
.your-order-table table .order-total th {
    border-bottom: medium none;
    font-size: 18px;
}
.your-order-table table .order-total td {
    border-bottom: medium none;
}
.your-order-table table tr.cart_item:hover {
    background: #F9F9F9
}
.your-order-table table tr.order-total td span {
    color: #464646;
    font-size: 20px;
}
.your-order-table table {} .payment-method {
    margin-top: 40px;
}

.payment-accordion {} .payment-accordion h3 {
    border-bottom: 0 none;
    margin-bottom: 10px;
    padding-bottom: 0;
}
.payment-accordion h3 a {
    color: #6f6f6f;
    font-size: 14px;
    padding-left: 25px;
    position: relative;
    text-transform: capitalize;
    text-decoration: none
}
.payment-content {} .payment-content p {
    font-size: 13px;
}
.payment-accordion img {
    height: 60px;
    margin-left: 15px;
}
.order-button-payment input {
    background: #464646 none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    height: 50px;
    margin: 20px 0 0;
    padding: 0;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    width: 100%;
    border: 1px solid transparent;
}
.order-button-payment input:hover {
    background: #7c7c7c;
    border: 1px solid #7c7c7c;
    color: #fff;
}
.coupon-info p.form-row input[type="submit"] {
    background: #252525 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    float: left;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    padding: 0 15px;
    text-shadow: none;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    width: inherit;
}
.mrg-nn {
    margin-bottom: 0;
}
#scrollUp {
    background: #333 none repeat scroll 0 0;
    bottom: 85px;
    color: #ffffff;
    cursor: pointer;
    display: none;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    right: 12px;
    text-align: center;
    width: 38px;
    z-index: 9999;
}
#scrollUp:hover {
    background: #7c7c7c none repeat scroll 0 0;
}
.sidebar-mega-menu2.sidebar-mega-menu nav > ul > li:hover > a,
.sidebar-mega-menu2.sidebar-mega-menu nav > ul > li > ul > li:hover > a,
.sidebar-mega-menu2.sidebar-mega-menu nav > ul > li > .mega-menu > ul > li:hover a,
.sidebar-mega-menu2.sidebar-mega-menu nav > ul li ul.sub-menu li:hover > a {
    color: #7c7c7c;
}
.green-color .portfolio-menu-style > button.active,
.green-color .portfolio-title > h3 a:hover,
.green-color .portfolio-title > a:hover {
    color: #7c7c7c;
}
.green-color .load-more > a:hover {
    background-color: #7c7c7c;
    border: 1px solid #7c7c7c;
}
.blue-color .portfolio-title > h3 a:hover,
.blue-color .portfolio-title > a:hover {
    color: #76c1e9;
}
.mfp-bg {
    z-index: 9999999;
}
.mfp-wrap {
    z-index: 99999999;
}
/*--------- 18. Login page ---------*/

.login-form-container {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ddd;
    padding: 60px 40px;
    text-align: left;
}
.login-text {
    margin-bottom: 30px;
    text-align: center;
}
.login-text h2 {
    color: #444;
    font-size: 30px;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.login-text span {
    font-size: 15px;
}

.password-reset-container input {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: none;
    color: #999;
    font-size: 14px;
    height: 40px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    width: 100%;
}

.password-reset-container input::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.password-reset-container input[type="checkbox"] {
    height: 15px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
    top: 1px;
    width: 17px;
}
.password-reset-container label {
    color: #777;
    font-size: 15px;
    font-weight: 400;
}

.password-form {
    width: 300px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.password-reset-container .password-reset-btn {
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
}

.login-form-container input {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: none;
    color: #999;
    font-size: 14px;
    height: 40px;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    width: 100%;
}

.login-form-container input::-moz-placeholder {
    color: #999;
    opacity: 1;
}
.login-toggle-btn {
    padding-top: 10px;
}
.login-form-container input[type="checkbox"], .login-form-container input[type="radio"] {
    height: 15px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
    top: 1px;
    width: 17px;
}
.login-form-container label {
    color: #777;
    font-size: 15px;
    font-weight: 400;
}
.login-toggle-btn > a {
    color: #777;
    float: right;
    transition: all 0.3s ease 0s;
}
.login-toggle-btn > a:hover {
    color: #000;
}
.button-box .default-btn {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 30px;
    color: #777;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 1;
    margin-top: 25px;
    padding: 12px 36px 10px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}
.button-box .default-btn:hover {
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
}
/* 19. Blog style */

.blog-meta li {
    color: #666;
    display: inline-block;
    letter-spacing: 1px;
    padding-right: 17px;
    text-transform: uppercase;
}
.blog-meta span,
.blog-meta a {
    padding-right: 17px;
}
.blog-content > h3 {
    color: #000000;
    font-size: 24px;
    letter-spacing: 1px;
    margin: 23px 0 27px;
    text-transform: uppercase;
}
.blog-content > h3 a {
    color: #333;
    font-weight: 500;
}
.blog-content > h3 a:hover {
    color: #7c7c7c;
}
.blog-content > p,
.tab-paragraph.text-center > p {
    color: #888888;
    font-size: 16px;
    line-height: 30px;
}
.tab-paragraph > p {
    margin: 0 auto;
    width: 72%;
}
.blog-video-img {
    position: relative;
}
.blog-video-img:before {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}
.blog-video-img a {
    color: #fffefe;
    content: "";
    display: inline-block;
    font-size: 72px;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 99;
    line-height: 1;
}
.different-video .blog-video-img a {
    font-size: 60px;
}
.blog-video-img a:hover {
    color: #7c7c7c;
}
.blog-meta.blog-meta2 li a {
    color: #999999;
}
.blog-meta.blog-meta2 li a:hover {
    color: #7c7c7c;
}
.blockquote {
    padding: 40px 80px;
    position: relative;
}
.blockquote {
    padding: 50px 180px 60px;
    position: relative;
}
.block-img1 {
    display: inline-block;
    left: 80px;
    position: absolute;
    top: 40px;
}
.block-img2 {
    bottom: 58px;
    display: inline-block;
    position: absolute;
    right: 80px;
}
.blockquote > p {
    color: #323232;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 35px;
}
.blog1-info-meta li {
    display: inline-block;
    margin-right: 15px;
}
.blog1-info-meta li a {
    color: #797878;
    letter-spacing: 1px;
    margin-right: 15px;
}
.blog1-info-meta li a:hover {
    color: #000;
}
.blog1-info-meta {
    margin: 30px 0 15px;
}
.single-blog1 img {
    width: 100%;
}
.blog1-info h3 a {
    color: #444444;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 30px;
    text-transform: uppercase;
}
.blog1-info h3 a:hover {
    color: #000;
}
.blog1-info > h3 {
    margin-bottom: 17px;
}
.blog1-info > a {
    color: #6a6a6a;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.blog1-related-title {
    margin-bottom: 115px;
    padding-left: 100px;
}
.blog1-related-title > h2 {
    color: #444444;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    position: relative;
    text-transform: uppercase;
}
.blog1-related-title > h2::before {
    background-color: #000;
    bottom: -36px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 120px;
}
.blog1-info > a:hover {
    color: #000;
    text-decoration: line-through;
}
.pro-large-img > img {
    width: 100%;
}
/* 20. Blog sidebar style */

.search-style2 {
    position: relative;
}
.sidebar-widget {
    background-color: #fff;
    padding: 20px;
}
.product-sidebar-area .sidebar-widget {
    background-color: inherit;
    padding: 0 0 11px;
}
.blog-sidebar2 .sidebar-widget {
    background-color: inherit;
    padding: inherit;
}
.single-blog2-middle img {
  width: 100%;
}
.search-style2 form input {
    background-color: transparent;
    border-color: #e5e5e5;
    border-style: solid;
    border-width: 0 0 1px;
    color: #666666;
    display: block;
    font-size: 12px;
    font-weight: 300;
    height: 52px;
    padding: 0 10px 0 63px;
    text-transform: uppercase;
}
.search-style2 form button {
    background-color: transparent;
    border: medium none;
    color: #000000;
    font-size: 24px;
    left: 0;
    padding: 0;
    position: absolute;
    top: 11px;
    transition: all 0.3s ease 0s;
}

.single-banner a > img {
    width: 100%;
}
.blog-banner-area {
    display: block;
    overflow: hidden;
}
.single-blog2-middle > img {
    width: 100%;
}
.sidebar-widget > a > img {
    width: 100%;
}
.video-img > img {
    width: 100%;
}
.self-info {
    padding: 37px 15px 50px;
}
.self-info > h3 {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}
.self-info > h3 a {
    color: #333;
}
.self-info > h3 a:hover {
    color: #7c7c7c;
}
.self-info > p {
    color: #000;
    margin: 0;
}
.self-info > p span {
    color: #7c7c7c;
    font-weight: bold;
}
.blog-social {
    margin-top: 43px;
}
.blog-social > h4 {
    color: #363535;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 39px;
    position: relative;
}
.blog-social ul li {
    display: inline-block;
    margin: 0 12px;
}
.blog-social ul li a {
    border: 1px solid #d9d9d9;
    border-radius: 50px;
    color: #999999;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    width: 40px;
}
.blog-social ul li a:hover {
    color: #fff;
    background-color: #7c7c7c;
    border: 1px solid #7c7c7c;
}
.blog-social h4::before {
    background-color: #565252;
    bottom: -13px;
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 50px;
}
.sidebar-banner a > img,
.blog-banner > img {
    width: 100%;
}
.blog-search form input {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #f1f1f1;
    color: #989797;
    font-size: 16px;
    font-weight: 300;
    height: 60px;
    padding: 2px 82px 2px 30px;
}
.blog-search form input::-webkit-input-placeholder {
    /* Chrome */

    color: #989797;
    opacity: 1;
}
.blog-search form input::-moz-placeholder {
    /* Firefox 19+ */

    color: #989797;
    opacity: 1;
}
.blog-search form {
    position: relative;
}
.blog-search form button {
    background-color: transparent;
    border: medium none;
    bottom: 0;
    color: #bbbbbb;
    font-size: 20px;
    height: 60px;
    padding: 0 10px;
    position: absolute;
    right: 23px;
    transition: all 0.3s ease 0s;
}
.blog-search form button:hover {
    color: #000;
}
.blog-search form button::before {
    background-color: #eaeaea;
    bottom: 10px;
    content: "";
    height: 40px;
    margin: 0 auto;
    position: absolute;
    right: 52px;
    width: 1px;
}
.sidebar-title {
    color: #333;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 60px;
    position: relative;
    text-transform: uppercase;
}
.sidebar-title::before {
    background-color: #000;
    bottom: -20px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 120px;
}
.blog-sidebar-menu a {
    color: #444444;
    font-size: 16px;
    font-weight: 600;
}
.blog-sidebar-menu li a {
    color: #444444;
    display: block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.blog-sidebar-menu li a span {
    float: right;
    color: #999999;
}
.blog-sidebar-menu > li {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 27px;
    padding-bottom: 11px;
}
.blog-sidebar-menu > li:hover a {
    color: #7c7c7c;
}
.blog-sidebar-menu > li:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.video-img {
    position: relative;
}
.video-img a {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 50%;
    color: #000;
    font-size: 22px;
    height: 50px;
    left: 0;
    line-height: 52px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
}
.video-img a:hover {
    background: #666 none repeat scroll 0 0;
    color: #fff;
}
.video-title h5 {
    color: #1c1c1c;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 8px;
}
.video-title h5 a {
    color: #1c1c1c;
    margin-bottom: 8px;
}
.video-title h5 a:hover {
    color: #7c7c7c;
}
.video-title > span {
    color: #979797;
    font-weight: normal;
}
.video-title {
    margin-top: 23px;
}
.sidebar-slider .owl-dot {
    background: #7c7c7c none repeat scroll 0 0;
    border: 2px solid #7c7c7c;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin: 0 4px;
    width: 10px;
}
.sidebar-slider .owl-dot.active {
    border: 2px solid #7c7c7c;
    height: 14px;
    width: 14px;
}
.sidebar-slider .owl-dots {
    margin-top: 16px;
    text-align: center;
}
.post-img {
    display: inline-block;
    float: left;
}
.sidebar-post > li {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
}
.sidebar-post > li:last-child {
    margin-bottom: 0px;
}
.post-text {
    padding-left: 141px;
}
.post-text > h4 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 22px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.post-text > h4 a {
    color: #2a2a2a;
}
.post-text > h4 a:hover {
    color: #7c7c7c;
}
.post-text > span {
    color: #333;
    font-size: 12px;
}
.tag > a {
    background-color: #f6f6f6;
    border-radius: 5px;
    color: #000000;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1;
    margin: 0 3px 12px 0;
    padding: 11px 15px 10px;
}
.tag > a:hover {
    background-color: #7c7c7c;
    color: #fff;
}
.blog2-meta > span {
    color: #666;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.blog2-meta > span i {
    font-size: 18px;
    padding-right: 12px;
}
.single-blog2-top {
    padding: 30px 0px 45px;
}
.single-blog2-top h2 {
    font-size: 19px;
    font-weight: 500;
    line-height: 34px;
}
.single-blog2-top h2 a {
    color: #000000;
}
.single-blog2-bottom {
    display: block;
    overflow: hidden;
    padding: 31px 0px 23px;
}
.blog2-meta {
    margin-bottom: 19px;
}
.single-blog2-bottom > p {
    font-size: 16px;
    line-height: 30px;
}
.single-blog2-bottom > a {
    border-bottom: 1px solid #bfbfbf;
    color: #000000;
    display: inline-block;
    font-weight: normal;
    letter-spacing: 2px;
    line-height: 1;
    text-transform: uppercase;
}
.blog2-user-info {
    margin-top: 40px;
}
.blog2-user-text {
    color: #777;
    display: inline-block;
    float: left;
    font-size: 14px;
    letter-spacing: 2px;
}
.blog2-user-text span a {
    color: #777;
}
.blog2-user-text span a:hover {
    color: #000;
}
.blog2-user-name {
    padding-right: 47px;
}
.blog2-user-share {
    display: inline-block;
    float: right;
}
.blog2-user-share > span {
    color: #777;
    display: inline-block;
    float: left;
    letter-spacing: 3px;
    margin-right: 14px;
}
.blog2-user-share > ul {
    display: inline-block;
}
.blog2-user-share > ul > li {
    display: inline-block;
    float: left;
    margin-left: 16px;
}
.blog2-user-share > ul > li:first-child {
    margin-left: 0;
}
.blog2-user-share a {
    color: #777777;
    font-size: 15px;
}
.single-blog2-top h2 a:hover,
.single-blog2-bottom > a:hover {
    color: #666;
}
.blog2-user-share a:hover {
    color: #000;
}
.blog-slider-active.owl-carousel .owl-nav div {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    bottom: 20px;
    color: #000000;
    font-size: 18px;
    height: 45px;
    line-height: 45px;
    opacity: 1;
    position: absolute;
    right: 20px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 45px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.blog-slider-active.owl-carousel .owl-nav div:hover {
    background: #7c7c7c none repeat scroll 0 0;
    color: #fff;
}
.blog-slider-active.owl-carousel .owl-nav div.owl-prev {
    right: 85px;
}
.blog2-video {
    position: relative;
}
.blog2-video > a {
    border: 1px solid #fff;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 48px;
    height: 80px;
    left: 50%;
    line-height: 80px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 80px;
}
.blog2-video > a:hover {
    border: 1px solid #ccc;
    color: #ccc;
}
.blog2-video > a i {
    line-height: 80px;
}
.page-nav.blog2-page-nav li {
    margin: 0 10px;
}
.page-nav.blog2-page-nav li a {
    margin: 0 20px 0 0;
}
.minim1-logo > h5 {
    border-bottom: 1px solid #5f5f5f;
    color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 2px;
    margin: 20px 0 0;
    padding-bottom: 19px;
    text-transform: uppercase;
}
.header-height3 .logo {
    display: block;
}
.header-height3 .shopping-cart a span.shop-count {
    background: #fff none repeat scroll 0 0;
    color: #000;
}
.logo-mainmenu-search {
    float: left;
    width: 100%;
}
.bread-menu-social {
    float: left;
    padding-top: 120px;
    width: 100%;
}
.bread-menu > ul li {
    color: #fff;
    display: inline-block;
    float: left;
    font-weight: 300;
    letter-spacing: 2px;
    padding-right: 28px;
    text-transform: uppercase;
}
.bread-menu > ul li a {
    color: #fff;
    padding-right: 23px;
}
.bread-share {
    display: inline-block;
    float: right;
}
.bread-share > span {
    color: #fff;
    display: inline-block;
    float: left;
    font-weight: 300;
    letter-spacing: 3px;
    margin-right: 14px;
    text-transform: uppercase;
}
.bread-share > ul {
    display: inline-block;
}
.bread-share > ul > li {
    display: inline-block;
    float: left;
    margin-left: 20px;
}
.bread-share > ul > li:first-child {
    margin-left: 0;
}
.bread-share a {
    color: #fff;
    font-size: 14px;
}
.sidebar-menu.blog-sidebar-menu {
    padding: 0;
}
/* 21. Blog details */

.blog-wrapper {
    background: #fff none repeat scroll 0 0;
    padding: 30px;
}
.blog-wrapper.none-bg-color2 {
    background: inherit;
    padding: 0;
}
.blog-title > h3 {
    color: #333;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 35px;
    margin: 0;
    text-transform: uppercase;
}
.blog-meta > span {
    color: #666666;
    letter-spacing: 4px;
}
.date {
    padding-right: 20px;
}
.workstation {
    padding-left: 23px;
}
.blog-meta {
    margin-top: 20px;
}
.minim-slider-2-area .owl-carousel .owl-nav div i {
    line-height: 40px;
}
.owl-carousel .owl-nav div.owl-prev {
    right: 85px;
}
.blog-slider {
    margin: 50px 0 41px;
}
.blo2-area .blog-slider {
    margin: 0px 0 0px;
}
.blog-info-dec > p {
    line-height: 30px;
}
.blog-info-dec span {
    display: inline-block;
    float: left;
    font-size: 60px;
    line-height: 1;
    margin: 0 5px 0 0;
}
blockquote {
    border-left: 5px solid #4b4a4a;
    color: #666666;
    font-style: italic;
    font-weight: 500;
    line-height: 30px;
    margin: 52px 0 54px 70px;
    padding: 0 70px 0 26px;
}
.blog-info > p {
    line-height: 30px;
}
.blog-info > ul > li {
    color: #666666;
    font-size: 16px;
    margin-bottom: 19px;
    position: relative;
}
.blog-info > ul > li::before {
    color: #999999;
    content: "\f040";
    font-family: FontAwesome;
    font-size: 21px;
    left: -35px;
    position: absolute;
}
.blog-info li:last-child {
    margin-bottom: 0px;
}
.blog-info > ul {
    margin: 47px 100px 46px;
}
.blog-img {
    margin: 50px 0;
}
.blog-img.blog-mrg-none {
    margin-top: 0;
}
.user-text {
    color: #666;
    display: inline-block;
    float: left;
    letter-spacing: 2px;
}
.user-share {
    display: inline-block;
    float: right;
}
.user-info {
    display: block;
    margin: 47px 0 53px;
    overflow: hidden;
}
.user-name {
    padding-right: 47px;
}
.user-share > ul {
    display: inline-block;
}
.user-share > span {
    color: #666666;
    display: inline-block;
    float: left;
    letter-spacing: 3px;
    margin-right: 14px;
}
.user-share > ul > li {
    display: inline-block;
    float: left;
    margin-left: 30px;
}
.user-share > ul > li:first-child {
    margin-left: 0px;
}
.user-share a {
    color: #666666;
}
.user-share a:hover {
    color: #222;
}
.blog-gallery-img > img {
    width: 100%;
}
.next-prev {
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    padding: 20px 0;
}
.next-prev a {
    background-color: #fff;
    border-radius: 5px;
    color: #666;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 8px 20px;
    text-transform: capitalize;
}
.next-prev a i {
    color: #666;
    font-size: 11px;
    transition: all .3s ease 0s;
}
.next-prev a:hover {
    background-color: #7c7c7c;
    color: #fff;
}
.next-prev a:hover i {
    color: #fff;
}
a.next-btn {
    float: right;
}
.author {
    display: block;
    margin: 100px 0;
    overflow: hidden;
}
.author-img {
    display: inline-block;
    float: left;
}
.author-info > h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.author-info > h4 a {
    color: #666666;
}
.author-info > h4 a:hover {
    color: #7c7c7c;
}
.user-text > span a:hover {
    color: #7c7c7c;
}
.author-info {
    padding: 0 80px 0 152px;
}
.author-info > h4 a span {
    color: #999999;
    font-size: 14px;
    margin-left: 7px;
}
.author-info > p {
    line-height: 25px;
    margin-bottom: 0;
}
.related-post-title {
    margin-top: 20px;
}
.related-post {
    display: block;
    overflow: hidden;
}
.single-related-post img {
    width: 100%;
}
.related-post-title > h3 {
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.related-post-title > h3 a {
    color: #202020;
}
.related-post-title > h3 a:hover {
    color: #7c7c7c;
}
.related-post-title > span {
    color: #636363;
    font-size: 13px;
}
.single-comment {
    position: relative;
}
.comment-img {
    display: inline-block;
    float: left;
}
.comment-text {
    padding-left: 150px;
}
.comment-text > h3 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 17px;
    text-transform: uppercase;
}
.comment-text > p {
    color: #525252;
    letter-spacing: 0.4px;
    padding-right: 25px;
}
.comment-border {
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 40px;
    padding-bottom: 40px;
}
.single-comment.comment-border:last-child {
    margin-bottom: 0px;
}
.parent-comment {
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 40px;
    padding-bottom: 40px;
}
.comment-text > a {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 50px;
    bottom: -16px;
    color: #4c4c4c;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 8px 20px 5px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
}
.comment-text > a:hover {
    background-color: #7c7c7c;
    color: #fff;
}
.replay-comment {
    margin-left: 150px;
}
.leave-comment > h3 {
    color: #222222;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 11px;
    text-transform: uppercase;
}
.leave-comment > p {
    color: #000000;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0;
}
.leave-comment-form {
    margin-top: 40px;
}
.leave-comment-form form textarea {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #dddddd;
    color: #666666;
    font-size: 14px;
    height: 178px;
    padding: 30px;
    letter-spacing: 1px;
}
.leave-comment-form form input {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #dddddd;
    color: #666;
    font-size: 14px;
    height: 48px;
    letter-spacing: 1px;
    padding: 2px 30px;
}
.leave-comment-form form input::-webkit-input-placeholder,
.leave-comment-form form textarea::-webkit-input-placeholder {
    /* Chrome */

    color: #666;
    opacity: 1;
}
.leave-comment-form form input::-moz-placeholder,
.leave-comment-form form textarea::-moz-placeholder {
    /* Firefox 19+ */

    color: #666;
    opacity: 1;
}
.leave-comment-form .submit {
    background-color: #7c7c7c;
    border: 1px solid #7c7c7c;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 12px 28px 10px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}
.leave-comment-form .submit:hover {
    background-color: #fff;
    color: #666;
    border: 1px solid #dddddd;
}
.blog-wrapper-res .blog-info {
    margin-top: 50px;
}

.margin-form-availability {
    margin-bottom: 60px;
    overflow: hidden;
}

.choix {
    clear: both;
}

.container-availability {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.select-sessions-title {
    font-weight: bold;
    padding-bottom: 0px !important;
    color: black !important;
}

.modal-event-plan {
    width: 100%;
}

#event-seat-plan {
    width: 75%;
    margin-left: 12.5%;
}


.footer-shop-2 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.copyright-social {
    margin-top: 40px;
}

.map-btn-addCart {
    background-color: #28BB83;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 0.4px;
}

#availabilityMap {
    padding-right: 0px !important;
}

.login-form .required {
    position: relative;
}

.payment-bloc .login-form .required:after {
    content: "*";
    height: 50px;
    width: 5px;
    color: white;
    right: 15px;
    top: 22px;
    display: none;
}

.login-form .required:after {
     content: "*";
     height: 50px;
     width: 5px;
     display: block;
     color: red;
     right: 15px;
     top: 22px;
     position: absolute;
}
.table-content table .insurance-row td {
    background: #111;
    color: #fff;
    position: relative;
    padding: 20px 10px 15px;
}


.insurance-row .instruction {
    float: left;
    font-size: 13px;
    line-height: 20px;
    margin-left: 15px;
}
.insurance-row .instruction span {
    font-weight: 500;
}
.insurance-row .instruction a {
    color: #fff;
    font-size: 11px;
}
.insurance-row .action {
    float: right;
}
.insurance-row .action .btn-white {
    margin-right: 15px;
    background: #fff;
    border: 1px solid transparent;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    padding: 3px 10px;
    text-transform: uppercase;
    color: #111;
    border-radius: 2px;
}
.insurance-row .action .btn-transparent {
    font-size: 14px;
    color: #fff;
    padding: 3px 10px;
    line-height: 24px;
}
